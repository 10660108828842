import t from 'utils/translateFunc';
import momentTz from 'moment-timezone';
import ru_RU from 'antd/lib/locale/ru_RU';
import en_GB from 'antd/lib/locale/en_GB';
import uk_UA from 'antd/lib/locale/uk_UA';
import ro_RO from 'antd/lib/locale/ro_RO';
import { PROJECT } from 'config';

export const numberWithSpaces = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const roundFn = (number = 0, language = '') => {
  const q = Number(number);
  let roundNumber = {};

  q < 1.0e6
    ? (roundNumber = { number: numberWithSpaces(q), text: '' })
    : q < 1.0e9
      ? (roundNumber = {
        number: Math.round(q / 1.0e5) / 10,
        text: t('roundFn', 'M', language)
      })
      : (roundNumber = {
        number: numberWithSpaces(Math.round(q / 1.0e8) / 10),
        text: t('roundFn', 'B', language)
      });
  return roundNumber;
};
export const roundFnString = (number = 0) => {
  if (number) {
    const q = Number(number);
    if (q < 1.0e4) return `${numberWithSpaces(q)}`;
    if (q >= 1.0e4 && q < 1.0e6) return `${Math.round(q / 1.0e2) / 10} ${t('roundFn', 'K')}`;
    if (q >= 1.0e6 && q < 1.0e9) return `${Math.round(q / 1.0e4) / 100} ${t('roundFn', 'M')}`;
    if (q >= 1.0e9) return `${Math.round(q / 1.0e7) / 100} ${t('roundFn', 'B')}`;
    return q;
  }
  return '';
};

export function mathCeil(count) {
  const length = String(count).length;
  if (length >= 5 && length <= 6) {
    return `${Math.ceil((count / 1000) * 10) / 10}K`;
  }
  if (length > 6) {
    return `${Math.ceil((count / 1000000) * 10) / 10}M`;
  }
  return count;
}

export function mathRound(count) {
  const length = String(count).length;
  if (length >= 4 && length <= 6) {
    return `${Math.ceil((count / 1000) * 10) / 10}K`;
  }
  if (length > 6) {
    return `${Math.ceil((count / 1000000) * 10) / 10}M`;
  }
  return count;
}

export const declOfNum = (number, titles) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

export const getRandomInt = (min, max) => {
  const minValue = Math.ceil(min);
  const maxValue = Math.floor(max);
  return Math.floor(Math.random() * (maxValue - minValue)) + minValue;
};

export const isCustomPage = (pageData, paramsPage) => {
  let isCustom = false;
  if (pageData.find(item => Number(item.id) === Number(paramsPage))) {
    isCustom = true;
  }
  return isCustom;
};

export const isPresetPage = (dataPage, paramsPage) => {
  const preset = dataPage?.filter(item => Number(item.id) === Number(paramsPage));
  return preset?.length ? preset[0].readOnly : false;
};

export const isFilledArray = arr => (Array.isArray(arr) && arr.length ? true : null);

export const toUpperLatter = str => (str ? str.charAt(0).toUpperCase() + str.slice(1) : '');

export const toPascalCase = str => (str ? str.split('_').map(i => toUpperLatter(i.toLocaleLowerCase()))?.join('') : null);

export const debounce = (fn, delay) => {
  let timeOutId;
  return function (...args) {
    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    timeOutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export const addQueryParams = (params) => {
  const paramsUrl = new URLSearchParams();
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      const arr = Array.isArray(value);
      if (value && arr) {
        value.forEach((el) => { paramsUrl.append(key, el); });
      }
      if (value && !arr) {
        paramsUrl.append(key, value);
      }
    });
  }
  return paramsUrl.toString() ? `?${paramsUrl.toString()}` : '';
};

export const checkQueryParams = (params) => {
  const paramsUrl = new URLSearchParams();
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      const arr = Array.isArray(value);
      if (value && arr) {
        value.forEach((el) => {
          const elCode = `${value}`.includes('+') ? encodeURIComponent(el) : el;
          paramsUrl.append(key, elCode);
        });
      }
      if (value && !arr) {
        const valueCode = `${value}`.includes('+') ? encodeURIComponent(value) : value;
        paramsUrl.append(key, valueCode);
      }
    });
  }

  return paramsUrl.toString() ? `?${paramsUrl.toString()}` : '';
};

export const toneColor = (value, color) => {
  const lowerValue = value?.toLowerCase();
  if (lowerValue === 'негатив' || lowerValue === 'negative' || lowerValue === 'негатив') {
    return Array.isArray(color) ? color[0] : { color1: color?.color1 };
  }
  if (lowerValue === 'нейтрал' || lowerValue === 'neutral' || lowerValue === 'нейтрал') {
    return Array.isArray(color) ? color[1] : { color2: color?.color2 };
  }
  if (lowerValue === 'позитив' || lowerValue === 'positive' || lowerValue === 'позитив') {
    return Array.isArray(color) ? color[2] : { color3: color?.color3 };
  }
  return '';
};

export const widgetsToneColor = (arr, color) => arr.map(val => toneColor(val, color));

export const randomHexColor = () => `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;

const randomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);
const randomByte = () => randomNumber(0, 255);
const randomPercent = () => (randomNumber(50, 100) * 0.01).toFixed(2);
export const randomCssRgba = () => `rgba(${[randomByte(), randomByte(), randomByte(), randomPercent()].join(',')})`;

export const repeatColor = (data, colors) => {
  const dataLength = data?.length || 0;
  const colorsLength = colors?.length || 0;
  const diff = dataLength - colorsLength;
  if (diff > 0) {
    const colorsFillArr = Array(diff).fill(1).map(() => randomHexColor().toUpperCase());
    return colors.concat(colorsFillArr);
  }
  return colors;
};
export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
};

export function delayPromise(duration, data) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data);
    }, duration);
  });
}
export const timeZones = momentTz.tz.names()?.map(zona => ({ value: zona, label: zona }));

export async function loadMomentLocale(language) {
  if (language === 'uk') {
    await import('moment/locale/uk');
    return 'uk';
  }
  if (language === 'ru') {
    await import('moment/locale/ru');
    return 'ru';
  }
  if (language === 'ro') {
    await import('moment/locale/ro');
    return 'ro';
  }
  await import('moment/locale/en-gb');
  return 'en-gb';
}

export function loadAntdLang(language) {
  if (language === 'uk') return uk_UA;
  if (language === 'ru') return ru_RU;
  if (language === 'ro') return ro_RO;
  return en_GB;
}
export function changeTheme() {
  const mainHtml = document.querySelector('html');
  mainHtml.classList.add(`theme-${PROJECT === 'metricom' ? 'metricom' : 'original'}`);
}

export function changeFavicon(count) {
  const favicon = document.getElementById('favicon');
  const path = window.location.origin;
  const faviconUnseen = PROJECT === 'metricom' ? 'favicon-unseen-m.ico' : 'favicon-unseen.ico';
  const faviconMain = PROJECT === 'metricom' ? 'favicon-m.ico' : 'favicon.ico';
  const unseenIcon = `${path}/${faviconUnseen}`;
  const seenIcon = `${path}/${faviconMain}`;
  if (!count && favicon.href !== seenIcon) {
    favicon.href = seenIcon;
  }
  if (count && favicon.href !== unseenIcon) {
    favicon.href = unseenIcon;
  }
}

export function wordToHexColor(word) {
  let hash = 0;
  for (let i = 0; i < word.length; i += 1) {
    hash = (hash * 31 + word.charCodeAt(i)) % 255;
  }
  let color = '#';
  for (let j = 0; j < 3; j += 1) {
    const value = (hash + (j * 70)) % 255;
    color += (`0${value.toString(16)}`).slice(-2);
  }
  return color;
}

export const browserLanguage = () => {
  if (navigator?.language === 'ru') return 'ru';
  if (navigator?.language === 'uk') return 'uk';
  if (navigator?.language === 'ro') return 'ro';
  return 'en';
};

export function clearEmtyValues(obj) {
  if (!obj) return null;
  const clearObj = Object.entries(obj).filter(([, value]) => (Array.isArray(value) ? !!value?.length : !!value));
  return Object.fromEntries(clearObj);
}
