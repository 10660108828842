import cn from 'classnames';
import { Icon } from 'Components';
import { roundFnString } from 'utils/common';
import { bool, number, oneOfType, string } from 'prop-types';
import colors from 'styles/_colors.scss';
import classes from './IconsListMediaDetails.module.scss';

function IconMedia({ count = 0, text = '', type, size, rate }) {
  return (
    <div className={cn(classes.iconWrap)}>
      <Icon
        text={text}
        type={type}
        height={size || ''}
        width={size || ''}
        color={colors.grey450}
        textClass={cn(classes.iconText)}
        className={cn(classes.iconTextWrap)}
      />
      {!rate
        ? <strong className={cn(classes.iconCount)}>{roundFnString(count) || '0'}</strong>
        : Array(5).fill(null).map((_, i) => (
          <Icon
            key={i}
            height={18}
            width={18}
            type={count > i ? 'kid_star' : 'kid_star_outlined'}
            color={count > i ? colors.grey600 : colors.iconMainColor}
          />
        ))}
    </div>
  );
}

IconMedia.propTypes = { count: oneOfType([number, string]), text: string, type: string, size: oneOfType([number, string]), rate: bool };

export default IconMedia;
