import t from 'utils/translateFunc';
import * as filterRequests from 'storage/filters/requests';
import colors from 'styles/_colors.scss';
import { PROJECT } from 'config';

export const tones = language => [
  { id: 'negative', name: t('mentionWidget', 'negative', language) },
  { id: 'neutral', name: t('mentionWidget', 'neutral', language) },
  { id: 'positive', name: t('mentionWidget', 'positive', language) }
];

export const genders = language => [
  { id: 'male', name: t('creatorLngFilter', 'MALE', language) },
  { id: 'female', name: t('creatorLngFilter', 'FEMALE', language) }
];
export const roles = language => [{ id: 'subject', name: t('characterFilter', 'SUBJECT', language) },
  { id: 'secondary', name: t('characterFilter', 'SECONDARY', language) },
  { id: 'object', name: t('characterFilter', 'OBJECT', language) }];

export const mediaTypes = language => [
  { id: 'internet', title: t('mediaTypesFilter', 'INTERNET', language), icon: 'internet' },
  { id: 'agency', title: t('mediaTypesFilter', 'AGENCY', language), icon: 'agency' },
  { id: 'press', title: t('mediaTypesFilter', 'PRESS', language), icon: 'press' },
  { id: 'radio', title: t('mediaTypesFilter', 'RADIO', language), icon: 'radio' },
  { id: 'socialnetwork', title: t('mediaTypesFilter', 'SOCIALNETWORK', language), icon: 'socialnetwork' },
  { id: 'forum', title: t('mediaTypesFilter', 'FORUM', language), icon: 'forum' },
  { id: 'review', title: t('mediaTypesFilter', 'REVIEW', language), icon: 'review' },
  { id: 'tv', title: t('mediaTypesFilter', 'TV', language), icon: 'tv' }].filter(({ id }) => (PROJECT === 'metricom' ? (id === 'internet' || id === 'socialnetwork' || id === 'forum') : true));

export const postTypes = language => [
  { id: 'post', name: t('postTypes', 'post', language), icon: 'post' },
  { id: 'repost', name: t('postTypes', 'repost', language), icon: 'reposts' },
  { id: 'extendedRepost', name: t('postTypes', 'extendedRepost', language), icon: 'extended_repost' },
  { id: 'comment', name: t('postTypes', 'comment', language), icon: 'comments' },
  { id: 'replyComment', name: t('postTypes', 'replyComment', language), icon: 'reply_comment' }];

export const creatorsAge = [{ id: 'creator_age_from', name: '' }, { id: 'creator_age_to', name: '' }];
export const audience = [{ id: 'audience_from', name: '' }, { id: 'audience_to', name: '' }];
export const context = [{ id: 'words', name: 'Популярні слова' }, { id: 'links', name: 'Популярні посилання' }, { id: 'hashtags', name: 'Популярні хештеги' }];

export const progressColors = { negative: colors.pink500, neutral: colors.grey300, positive: colors.green400 };

export const dataProgress = [{ value: 0, text: 'Нейтрал', color: progressColors.neutral, id: 'neutral', link: '#' }];

export const filtersList = [
  'icontains',
  'creator',
  'creators',
  'creator_gender',
  'language',
  'country',
  'region',
  'city',
  'source',
  'level',
  'subject',
  'subtopic',
  'publication_place',
  'keyword',
  'media_type',
  'post_type',
  'tone',
  'character',
  'tag',
  'words',
  'links',
  'hashtags',
  'creator_gender',
  'creator_age_from',
  'creator_age_to',
  'audience_to',
  'audience_from'
];

export const parseFilterList = ({ filterName, filterData, language }) => {
  if (filterData && filtersList.includes(filterName)) {
    return Array.isArray(filterData) ?
      filterData.map(el => ({ id: typeof el === 'object' ? el?.id : el, name: typeof el === 'object' ? (el?.name || el?.title) : el, filterName, type: t('filtrationPanel', filterName.toUpperCase(), language) }))
      : { id: filterName, name: filterData, filterName, type: t('filtrationPanel', filterName.toUpperCase(), language) };
  }
  return null;
};

export function findRequestFunc(filterName) {
  if (filterName === 'keyword') return filterRequests.getKeywords;
  if (filterName === 'media_type') return filterRequests.getMediaTypes;
  if (filterName === 'tone') return filterRequests.getTones;
  if (filterName === 'tag') return filterRequests.getTags;
  if (filterName === 'post_type') return filterRequests.getPostTypes;
  if (filterName === 'country') return filterRequests.getCountries;
  if (filterName === 'region') return filterRequests.getRegions;
  if (filterName === 'city') return filterRequests.getCities;
  if (filterName === 'source') return filterRequests.getSources;
  if (filterName === 'subject') return filterRequests.getSubjectSources;
  if (filterName === 'creators' || filterName === 'creator') return filterRequests.getCreators;
  if (filterName === 'language') return filterRequests.getLanguages;
  if (filterName === 'character') return filterRequests.getCharacters;
  return null;
}
