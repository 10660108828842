import { useRef, useMemo, forwardRef, useState, useEffect, useImperativeHandle } from 'react';
import { Icons, Button, RadioButton } from 'Components';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { func, bool, shape, array, string } from 'prop-types';
import { validationEmail } from 'data/validation';
import { AnimatePresence } from 'framer-motion';
import isEqual from 'lodash/isEqual';
import { sendAlertsEvent } from 'services/amplitude/events';
import { PROJECT } from 'config';
import classes from './AlertDetails.module.scss';
import EmailsComponent from './EmailsComponent';
import QueryComponent from './QueryComponent';
import KeywordComponent from './KeywordComponent';
import NameComponent from './NameComponent';
import TelegramComponent from './TelegramComponent';
import WhatsappComponent from './WhatsappComponent';

const AlertDetails = forwardRef((
  { visible = false,
    refetchTelegram = false,
    unsavedChanges = () => null,
    data = {},
    telegramData = null,
    whatsappData = null,
    keywords = [],
    keywordsNextPage = () => null,
    hasKeywordsNextPage = false,
    onClose = () => null,
    onCreate = () => null,
    onChangeAlert = () => null,
    onOpenModal = () => null,
    onStartRefetchTelegram = () => null,
    errorRequest = null,
    alertsList = '',
    isLoading = false },
  ref
) => {
  const nodeRef = useRef(null);
  const emptyError = { name: '', keyword: '', query: '', emails: '', telegrams: '' };
  const [errors, setErrors] = useState(emptyError);

  useEffect(() => {
    !visible && refetchTelegram && onStartRefetchTelegram(false);
  }, [refetchTelegram, visible]);

  useImperativeHandle(ref, () => ({ warningMessage }));

  useEffect(() => {
    setErrors(emptyError);
  }, [data?.id, visible]);

  const errObj = useMemo(() => ({
    ...errors,
    ...(errors?.name ? { name: '' } : {}),
    ...(errors?.keyword && !!data?.keyword ? { keyword: false } : {}),
    ...(errors?.query && !!(data?.query || data?.query_builder) ? { query: '' } : {}),
    ...(errors?.telegrams && !!(data?.telegram_users.length || data?.emails?.length) ? { telegrams: '' } : {}),
    ...(errors?.emails && !!data?.telegram_users.length && !data?.emails?.length ? { emails: '' } : {}),
    ...(errors?.emails && !!data?.emails?.length && !data?.emails?.some(email => !!email && !validationEmail(email)) ? { emails: '' } : {})
  }), [data, errors]);

  useEffect(() => {
    const hasError = Object.values(errors).some(Boolean);
    hasError && !isEqual(errors, errObj) && setErrors(errObj);
  }, [data, errors]);

  useEffect(() => {
    if (!data?.searchByTab) {
      onChangeAlert({ ...data, searchByTab: data?.query || data?.query_builder ? 'query' : 'keyword' });
    }
  }, [data]);

  function warningMessage() {
    const errorObj = onValidateFields();
    setErrors(errorObj);
    const hasError = Object.values(errorObj).some(Boolean);
    const infoData = { ...data };
    hasError && (infoData.errors = true);
    onOpenModal({ action: 'editAlertConfirm', info: infoData });
  }

  function handleClose() {
    sendAlertsEvent({
      category: `${!data?.id ? 'Add' : 'Edit'} alert`,
      event: `Clicked_Close_Button_In_${!data?.id ? 'Add' : 'Edit_An'}_Alert_Modal`
    });
    unsavedChanges('only_close') ? warningMessage() : onClose();
  }

  function onValidateFields() {
    const hasEmailError = !!data?.emails?.length && data.emails.some(email => !validationEmail(email));
    const anyDelivery = !!data?.emails?.length || !!data?.telegram_users?.length || !!data?.whatsapp_accounts?.length;
    return {
      ...errors,
      name: !!(data?.name?.length > 100) && t('error', 'MAX_ALERT_LENGTH_100'),
      keyword: !!(data?.searchByTab === 'keyword' && !data?.keyword) && !(data?.query || data?.query_builder),
      query: !!(data?.searchByTab === 'query' && !(data?.query || data?.query_builder)) && !data?.keyword,
      emails: (errorRequest?.emails || !anyDelivery || hasEmailError),
      telegrams: !anyDelivery,
      whatsapps: !anyDelivery
    };
  }

  function onSubmit() {
    const errorObj = onValidateFields();
    setErrors(errorObj);
    const hasError = Object.values(errorObj).some(Boolean);
    if (hasError || isLoading) return;
    const properties = {
      isEditable: data?.editable,
      isEmails: !!data?.emails?.length,
      isEnabled: data?.is_enabled,
      isKeyword: !!data?.keyword,
      isQuery: !!data?.query,
      isQueryBuilder: !!data?.query_builder,
      isTelegramUsers: !!data?.telegram_users?.length,
      isWhatsappUsers: !!data?.whatsapp_accounts?.length,
      ...(data?.id ? { user: data?.user || {} } : {})
    };
    sendAlertsEvent({ category: `${data?.id ? 'Edit' : 'Add'} alert`, event: `${data?.id ? 'edit' : 'add'}_alert_${alertsList}`, properties });
    data?.id ? (unsavedChanges() && onOpenModal({ action: 'editAlert', info: data })) : onCreate(data);
  }

  function onChangeSearchBy() {
    if (data?.searchByTab !== 'keyword') {
      onChangeAlert({ ...data, searchByTab: 'keyword' });
      !!(errors?.keyword || errors?.query) && setErrors({ ...errors, keyword: '', query: '' });
    }
    if (data?.searchByTab !== 'query' && alertsList === 'alerts-simple') {
      onChangeAlert({ ...data, searchByTab: 'query' });
      !!(errors?.keyword || errors?.query) && setErrors({ ...errors, keyword: '', query: '' });
    }
  }

  return (
    <div className={classes.alertDetails} ref={nodeRef}>
      {visible && (
        <>
          <div className={classes.detailsHeader}>
            {t('alerts', !data?.id ? 'CREATE_ALERT' : 'EDIT_ALERT')}
            <button
              className={classes.exitBtn}
              onClick={handleClose}
              type="button"
            >
              <Icons
                icon="Icn_Clear"
                className={cn(classes.icon)}
              />
            </button>
          </div>
          <div className={classes.detailsBody}>
            <NameComponent
              dataName={data?.name}
              error={errors?.name}
              onChange={name => onChangeAlert({ ...data, name })}
            />
            <div className={classes.setTabWrapper}>
              <div className={classes.setTabLabel}>
                {t('alerts', 'SEARCH_BY')}
              </div>
              <div>
                <RadioButton
                  value="keyword"
                  label={t('alerts', 'ON_KEYWORD')}
                  checked={data?.searchByTab === 'keyword'}
                  onChange={onChangeSearchBy}
                />
                {alertsList === 'alerts-simple' && (
                <RadioButton
                  value="query"
                  checked={data?.searchByTab === 'query'}
                  label={t('alerts', 'ON_QUERY')}
                  onChange={onChangeSearchBy}
                />
                )}
              </div>
            </div>
            <AnimatePresence initial={false}>
              <div className={classes.searchByWrapper}>
                {data?.searchByTab === 'keyword' ? (
                  <KeywordComponent
                    keywords={keywords}
                    keywordsNextPage={keywordsNextPage}
                    hasKeywordsNextPage={hasKeywordsNextPage}
                    dataKeyword={data?.keyword}
                    error={errors?.keyword}
                    onChange={keyword => onChangeAlert({ ...data, keyword, query: '', query_builder: null })}
                  />
                ) : (
                  <QueryComponent
                    name={data?.name}
                    query={data?.query}
                    error={errors?.query}
                    queryBuilder={data?.query_builder}
                    onOpenModal={onOpenModal}
                  />
                )}
              </div>
            </AnimatePresence>
            <div className={classes.setTabWrapper}>
              <div className={classes.setTabLabel}>
                {t('alerts', 'SEND_BY')}
              </div>
            </div>
            <div className={classes.deliveryWrapper}>
              <div className={classes.deliveryLabel}>Email</div>
              <EmailsComponent
                dataEmails={data?.emails}
                onChange={(emails) => { onChangeAlert({ ...data, emails }); setErrors({ ...errors, emails: '' }); }}
                onChangeError={emails => setErrors({ ...errors, emails })}
                error={errors?.emails}
              />

              {PROJECT !== 'metricom' && (
              <>
                <div className={classes.deliveryLabel}>Telegram</div>
                <TelegramComponent
                  telegramData={telegramData}
                  dataTelegramUsers={data?.telegram_users}
                  onOpenModal={onOpenModal}
                  onStartRefetchTelegram={onStartRefetchTelegram}
                  refetchTelegram={refetchTelegram}
                  onChange={(telegram_users) => { onChangeAlert({ ...data, telegram_users }); }}
                  error={errors?.telegrams}
                />
              </>
              )}
              <div className={classes.deliveryLabel}>WhatsApp</div>
              <WhatsappComponent
                whatsappData={whatsappData}
                dataWhatsappUsers={data?.whatsapp_accounts}
                onOpenModal={onOpenModal}
                onChange={(whatsapp_accounts) => { onChangeAlert({ ...data, whatsapp_accounts }); }}
                error={errors?.whatsapps}
              />
            </div>
          </div>
          <div className={classes.detailsFooter}>
            <div className={classes.buttonBox}>
              {data?.id && (
                <Button
                  onClick={() => {
                    sendAlertsEvent({ category: 'Delete alert', event: 'Clicked_Delete_Alert_Button' });
                    onOpenModal({ action: 'removeAlert', info: data });
                  }}
                  textButton={t('btn', 'DELETE')}
                  className={classes.deleteBtn}
                />
              )}
              <Button
                onClick={() => {
                  sendAlertsEvent({
                    category: `${!data?.id ? 'Add' : 'Edit'} alert`,
                    event: `cancel_${data?.id ? 'edit' : 'add'}_alert_${alertsList}`
                  });
                  onClose();
                }}
                textButton={t('btn', 'CANCEL')}
                className={classes.cancelBtn}
                purpleTransparent32
              />
              <Button onClick={onSubmit} disabled={isLoading} textButton={t('btn', !data?.id ? 'CREATE_ALERT' : 'EDIT_ALERT')} green32 />
            </div>
          </div>
        </>
      )}
    </div>
  );
});

AlertDetails.propTypes = {
  visible: bool,
  refetchTelegram: bool,
  unsavedChanges: func,
  telegramData: shape({}),
  whatsappData: shape({}),
  errorRequest: shape({}),
  keywords: array,
  keywordsNextPage: func,
  hasKeywordsNextPage: bool,
  data: shape({}),
  onClose: func,
  onCreate: func,
  onStartRefetchTelegram: func,
  onChangeAlert: func,
  onOpenModal: func,
  alertsList: string,
  isLoading: bool
};

export default AlertDetails;
