import { useState, useRef, memo } from 'react';
import cn from 'classnames';
import { Icon, PopoverWindow, BodyCardCreator } from 'Components';
import { string, func, bool, shape, number, oneOfType } from 'prop-types';
import { sendFeedEvent } from 'services/amplitude/events';
import colors from 'styles/_colors.scss';
import classes from './CreatorPlace.module.scss';

const CreatorPlacePopover = memo(({
  info = null,
  idBind = '',
  subtype = '',
  tooltip = '',
  tooltipBtn = '',
  childrenClassName = '',
  language = 'uk',
  onSubmit = () => null,
  nextArrow = false,
  shortInfo = false
}) => {
  const bodyRef = useRef();
  const [visible, setVisible] = useState(false);
  const publicationPlaceName = info?.publication_place_name || '';
  const publicationPlaceSubcribers = info?.publication_place_subscribers_count || 0;
  const creatorName = info?.creators[0]?.name || '';
  const creatorSubcribers = info?.creators_subscribers_count || 0;
  const name = (subtype === 'creator' ? creatorName : publicationPlaceName) || '';
  const subscribersCount = (subtype === 'creator' ? creatorSubcribers : publicationPlaceSubcribers) || '0';
  const eventList = ['creator', 'publication_place'];
  const isDetails = idBind === 'mention-details';

  function afterChange(value) {
    value && !visible && setVisible(true);
    !value && visible && setTimeout(() => { setVisible(false); }, 350);
  }
  function onClose() {
    visible && bodyRef?.current?.onClosePopover();
    visible && setTimeout(() => { setVisible(false); }, 350);
  }

  function handleSubmit(value) {
    onSubmit({ ...value, body: { name: subtype } });
    onClose();
  }

  return (
    !!name && (
      <PopoverWindow
        ref={bodyRef}
        idBind={idBind}
        afterChange={afterChange}
        language={language}
        headerText={name}
        headerUrl={info?.url}
        overflow={{ adjustX: true, adjustY: true }}
        infoBtn={{ type: 'people', role: 'button', tooltipText: tooltipBtn, text: subscribersCount }}
        body={visible && (
          <BodyCardCreator
            language={language}
            info={info}
            onSubmit={handleSubmit}
            model_key={subtype === 'creator' ? 'creators' : 'publication_place'}
          />
        )}
        className={classes.creatorWrapper}
        childrenClassName={childrenClassName}
        placement={(subtype === 'publication_place' && isDetails) ? 'bottomRight' : 'bottom'}
      >
        <div className={cn(classes.creatorTitle, isDetails && !shortInfo && classes.creatorTitle_more)} onClick={() => setVisible(false)}>
          {nextArrow && (
            <Icon
              type="next_arrow"
              className={cn(classes.creatorNextArrow)}
              btnType={cn(classes.creatorPeopleIcon)}
            />
          )}
          {!!subscribersCount && info?.media_type === 'internet' && !shortInfo && !!isDetails && (
            <Icon
              type="group"
              color={colors.grey800}
              width={14}
              height={14}
              {...(!visible && !isDetails && { role: 'button' })}
              tooltipText={tooltipBtn}
              text={subscribersCount}
              className={cn(
                classes.creatorPeople,
                isDetails && classes.creatorPeople_details,
                !!visible && !isDetails && classes.creatorPeople_icon
              )}
              btnType={cn(classes.creatorPeopleIcon)}
              textClass={classes.creatorTextClass}
              onClick={() => {
                eventList.includes(subtype) && sendFeedEvent({ category: isDetails ? 'Full text' : 'Article', event: `article_count_${subtype}` });
              }}
            />
          )}

          {name && (
            <Icon
              type=""
              {...(!visible && { role: 'button' })}
              tooltipText={tooltip}
              text={name}
              className={cn(classes.creatorPeople, classes.creatorPeople_reverse)}
              btnType={cn(classes.creatorPeopleIcon, classes.creatorPeopleIcon_reverse)}
              onClick={() => {
                eventList.includes(subtype) && sendFeedEvent({ category: isDetails ? 'Full text' : 'Article', event: `article_menu_${subtype}` });
              }}
            />
          )}
          {!shortInfo && !!isDetails && <span className={classes.creatorPeopleHeader}>{info?.media_type === 'internet' ? tooltipBtn : tooltip}</span>}
        </div>
      </PopoverWindow>
    )
  );
});

CreatorPlacePopover.propTypes = {
  info: shape({}),
  childrenClassName: string,
  idBind: oneOfType([string, number]),
  subtype: string,
  tooltip: string,
  tooltipBtn: string,
  language: string,
  onSubmit: func,
  nextArrow: bool,
  shortInfo: bool
};

export default CreatorPlacePopover;
