import colors from 'styles/_colors.scss';
import { PROJECT } from 'config';
import { iconConfig } from './sourceConfig';

export const sourcePopoverColorsConfig = {
  facebook: {
    background: 'rgba(218, 232, 255, 1)',
    backgroundActive: 'rgb(234,244,255)',
    text: 'rgba(9, 102, 255, 1)',
    iconColor: colors.iconColorSourcePopover
  },
  instagram: {
    background: 'rgba(255, 242, 247, 1)',
    backgroundActive: 'rgb(255,249,252)',
    text: 'rgba(255, 0, 106, 1)',
    iconColor: colors.iconColorSourcePopover
  },
  whatsapp: {
    background: 'rgb(169,243,196)',
    backgroundActive: colors.greenA100,
    text: colors.green700m,
    iconColor: colors.iconColorSourcePopover
  },
  twitter: {
    background: 'rgba(234, 234, 234, 1)',
    backgroundActive: 'rgb(243,243,243)',
    text: 'rgba(59, 59, 59, 1)',
    iconColor: colors.iconColorSourcePopover
  },
  youtube: {
    background: 'rgba(255, 236, 236, 1)',
    backgroundActive: 'rgb(255,248,248)',
    text: 'rgba(255, 3, 2, 1)',
    iconColor: colors.iconColorSourcePopover
  },
  telegram: {
    background: 'rgba(209, 240, 255, 1)',
    backgroundActive: 'rgb(235,248,255)',
    text: 'rgba(39, 165, 228, 1)',
    iconColor: colors.iconColorSourcePopover
  },
  tiktok: {
    background: 'rgba(255, 231, 238, 1)',
    backgroundActive: 'rgb(255,244,247)',
    text: 'rgba(255, 0, 79, 1)',
    iconColor: colors.iconColorSourcePopover
  },
  linkedin: {
    background: 'rgba(218, 232, 255, 1)',
    backgroundActive: 'rgb(241, 246, 255)',
    text: 'rgba(10, 102, 194, 1)',
    iconColor: colors.iconColorSourcePopover
  },
  google_maps: {
    background: 'rgb(168, 250, 198)',
    backgroundActive: colors.greenA100,
    text: colors.green700m,
    iconColor: colors.iconColorSourcePopover
  },
  internet: {
    background: 'rgb(217, 224, 249)',
    backgroundActive: 'rgb(233, 237, 253)',
    text: 'rgb(46,87,253)',
    iconColor: colors.iconColorSourcePopover
  },
  agency: {
    background: 'rgb(248,188,203)',
    backgroundActive: 'rgb(251,220,227)',
    text: 'rgb(189,41,77)',
    iconColor: colors.iconColorSourcePopover
  },
  forum: {
    background: 'rgb(202,193,251)',
    backgroundActive: 'rgb(216,211,246)',
    text: colors.font300,
    iconColor: colors.iconColorSourcePopover
  },
  review: {
    background: 'rgb(202,193,251, 0.3)',
    backgroundActive: 'rgb(216,211,246, 0.3)',
    text: 'rgba(235, 128, 52, 1)',
    iconColor: 'rgba(0, 0, 0, 0.84)'
  },
  press: {
    background: 'rgb(244,227,174)',
    backgroundActive: 'rgb(251,243,217)',
    text: 'rgb(85,69,27)',
    iconColor: colors.iconColorSourcePopover
  },
  radio: {
    background: 'rgb(221,205,251)',
    backgroundActive: 'rgb(232,225,248)',
    text: 'rgb(62,37,112)',
    iconColor: colors.iconColorSourcePopover
  },
  tv: {
    background: 'rgb(246, 189, 234)',
    backgroundActive: 'rgb(248,222,242)',
    text: 'rgba(122, 14, 99, 1)',
    iconColor: colors.iconColorSourcePopover
  },
  socialnetwork: {
    background: 'rgb(213, 247, 232)',
    backgroundActive: 'rgb(231, 251, 242)',
    text: 'rgb(10, 181, 105)',
    iconColor: colors.iconColorSourcePopover
  },
  default: {
    background: 'rgb(173, 244, 199)',
    backgroundActive: colors.greenA100,
    text: colors.green700m,
    iconColor: colors.iconColorSourcePopover
  }
};

export const getSourceColors = (sourceName) => {
  for (const [key, values] of Object.entries(iconConfig)) {
    if (values.includes(sourceName)) return sourcePopoverColorsConfig?.[key] || sourcePopoverColorsConfig.default;
  }
  return sourcePopoverColorsConfig?.[sourceName] || sourcePopoverColorsConfig.default;
};
export function fontColor(color) {
  const fontColorValue = (PROJECT === 'metricom' ? colors[`font${color}Green`] : colors[`font${color}`]);
  return fontColorValue || PROJECT === 'metricom' ? colors.font50Green : colors.font50;
}
