/* eslint-disable react/jsx-filename-extension */
import { useQuery, useMutation, useInfiniteQuery, useQueryClient } from 'react-query';
import * as actions from 'storage/actions';
import * as requests from 'storage/news/request';
import { openNotification } from 'Components';
import t from 'utils/translateFunc';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { sendFeedEvent } from 'services/amplitude/events';

export const useGetNewsList = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { articleId, pageId, portal } = useParams();
  const filtersQuery = useSelector(state => state?.filters?.filtersQuery);
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const currentPage = useSelector(state => state?.pages?.currentPage);
  const date = useSelector(state => state?.datePicker?.dateObj);
  const periodLoaded = useSelector(state => state?.datePicker?.periodLoaded);
  const language = useSelector(state => state?.userInfo?.language);
  const portalsList = useSelector(state => state.portals?.portalsList);
  const hasPortal = !!portal && !!portalsList?.find(el => el?.slug === portal);
  const startLoad = pageId === 'all' || (pageId !== 'all' && !!currentPage);
  return useQuery({
    queryKey: ['getNewsList', portal_slug, filtersQuery, date, language],
    queryFn: ({ signal }) => requests.getNewsList({ portal_slug, signal, params: filtersQuery }),
    enabled: !!portal_slug && hasPortal && startLoad && periodLoaded,
    onSuccess: (resp) => {
      articleId && queryClient.refetchQueries({ queryKey: ['getArticleDetails', portal_slug, articleId, date], exact: true });
      const opened = resp?.data?.length ? resp?.data.map(el => el.keyword.id) : [];
      dispatch(actions.toggleOpenNewsList(opened));
      dispatch(actions.addNewsList(resp));
    },
    onError: () => {
      dispatch(actions.clearNewsList());
      openNotification({ message: t('notif', 'SOMETHING_WRONG', language) });
    }
  });
};

export const useGetPreviewAricles = ({ body, period, enabled, previewMode }) => {
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  return useQuery({
    enabled,
    queryKey: ['getPreviewAricles', body, period, previewMode],
    queryFn: ({ signal }) => requests.getPreviewAricles({ portal_slug, signal, body, params: period, previewMode })
  });
};

export const useGetInfinityPreviewAricles = ({ body, enabled }) => {
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  return useInfiniteQuery({
    enabled,
    queryKey: ['getPreviewInfinityArticles', body],
    getNextPageParam: lastPage => lastPage?.next_url,
    queryFn: ({ signal, pageParam }) => requests.getPreviewInfinityAricles({ portal_slug, signal, pageParam, body })
  });
};

export const useGetNewsListOffset = () => {
  const dispatch = useDispatch();
  const { keywords_offset, openedNewsList } = useSelector(state => state?.news);
  const filtersQuery = useSelector(state => state?.filters?.filtersQuery);
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  return useMutation({
    mutationKey: 'getNewsListOffset',
    mutationFn: () => requests.getNewsList({ portal_slug, params: { ...filtersQuery, keywords_offset } }),
    onSuccess: (resp) => {
      dispatch(actions.addNewsListOffset(resp));
      const opened = resp?.data?.length ? resp?.data.map(el => el.keyword.id) : [];
      dispatch(actions.toggleOpenNewsList(openedNewsList.concat(opened)));
    }
  });
};

export const useCheckMoreNews = () => {
  const dispatch = useDispatch();
  const date_since = useSelector(state => state?.news?.time);
  const { filtersQuery } = useSelector(state => state?.filters);
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const params = { ...filtersQuery, date_since };
  const { portal } = useParams();
  const portalsList = useSelector(state => state.portals?.portalsList);
  const hasPortal = !!portal && !!portalsList?.find(el => el?.slug === portal);
  const needUpdate = useSelector(state => state?.news?.needUpdate);
  return useQuery({
    enabled: needUpdate && !!date_since && !!portal_slug && hasPortal,
    queryKey: ['getNewsCountSince', portal_slug, date_since],
    queryFn: () => needUpdate && requests.getNewsCountSince({ portal_slug, params }),
    onSuccess: (resp) => { dispatch(actions.changeNewsCount(resp)); },
    refetchIntervalInBackground: true
  });
};

export const useLastUpdateTime = () => {
  const { portal } = useParams();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const serverUpdating = useSelector(state => state?.auth?.serverUpdating);
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);
  const notAccess = window.location.pathname.includes('not_access');
  const portalsList = useSelector(state => state.portals?.portalsList);
  const hasPortal = !!portal && !!portalsList?.find(el => el?.slug === portal);
  return useQuery({
    enabled: !!portal_slug && isAuthenticated && hasPortal && !notAccess,
    queryKey: ['getLastUpdateTime', portal_slug],
    queryFn: () => requests.getLastUpdateTime({ portal_slug }),
    onSuccess: (resp) => {
      dispatch(actions.updateTime(resp));
      queryClient.refetchQueries({ queryKey: ['getNewsCountSince', portal_slug], exact: true });
      serverUpdating && dispatch(actions.changeServerUpdating(false));
    } });
};

export const useGetNewsAllCount = () => {
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const filtersQuery = useSelector(state => state?.filters?.filtersQuery);
  const periodLoaded = useSelector(state => state?.datePicker?.periodLoaded);
  const { portal, pageId } = useParams();
  const params = { ...filtersQuery };
  const date = useSelector(state => state?.datePicker?.dateObj);
  const portalsList = useSelector(state => state.portals?.portalsList);
  const hasPortal = !!portal && !!portalsList?.find(el => el?.slug === portal);
  if (!!pageId && pageId !== 'all') {
    params.page = pageId;
  }
  return useQuery({
    enabled: !!portal_slug && hasPortal && periodLoaded,
    queryKey: ['getNewsAllCount', portal_slug, filtersQuery, date],
    queryFn: ({ signal }) => requests.getNewsAllCount({ portal_slug, signal, params })
  });
};

export const useUpdateTime = () => {
  const dispatch = useDispatch();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);
  const time = useSelector(state => state?.news?.time);
  const serverUpdating = useSelector(state => state?.auth?.serverUpdating);
  const notAccess = window.location.pathname.includes('not_access');
  return useQuery({
    enabled: !!portal_slug && isAuthenticated && !notAccess,
    queryKey: ['getUpdateTime', portal_slug],
    queryFn: () => requests.getUpdateTime({ portal_slug }),
    onSuccess: (resp) => {
      !!resp?.time && resp?.time !== time && dispatch(actions.updateTime(resp));
      serverUpdating && dispatch(actions.changeServerUpdating(false));
    },
    cacheTime: 50,
    initialData: undefined,
    refetchInterval: 60000,
    refetchIntervalInBackground: true
  });
};

export const useEditManyArticles = () => {
  const dispatch = useDispatch();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const { filtersQuery, tabItem } = useSelector(state => state?.filters);
  const language = useSelector(state => state?.userInfo?.language);
  const chekedArticles = useSelector(state => state?.news?.chekedArticles);
  const { pageId, articleId } = useParams();
  const history = useNavigate();
  const queryClient = useQueryClient();
  const someUnprocessed = chekedArticles.some(({ processed }) => !processed);
  const date = useSelector(state => state?.datePicker?.dateObj);
  return useMutation({
    mutationKey: 'editManyArticles',
    mutationFn: ({ article_ids, value }) => {
      const body = value ? article_ids.map(el => ({ ...el, ...value })) : article_ids;
      const articles = value && { article_ids: article_ids?.reduce((acc, curr) => (curr?.article_ids ? acc.concat(curr?.article_ids) : acc), []) };
      if (typeof value?.processed !== 'undefined') {
        return (tabItem?.action === 'unprocessed' || someUnprocessed) ? requests.addToProcessed({ portal_slug, body: articles }) : requests.removeManyFromProcessed({ portal_slug, body: articles });
      }
      if (typeof value?.bookmarked !== 'undefined') {
        return !value?.bookmarked ? requests.removeManyFromBookmark(articles) : requests.addToBookmark(articles);
      }
      return requests.editManyArticles({ portal_slug, body });
    },
    onSuccess: (_, { article_ids, value, notification }) => {
      const bookmarked = typeof value?.bookmarked !== 'undefined' && tabItem?.action === 'bookmarked';
      const unprocessed = typeof value?.processed !== 'undefined' && (tabItem?.action === 'unprocessed');
      const processed = typeof value?.processed !== 'undefined' && tabItem?.action === 'processed';
      if (bookmarked || unprocessed || processed) {
        dispatch(actions.deleteManyArticles());
        articleId && history(`/${portal_slug}/mentions/${pageId || 'all'}`);
      } else {
        value
          ? dispatch(actions.changeValueInArticles({ article_ids, value }))
          : dispatch(actions.changeManyValuesInArticles(article_ids));
      }
      notification && openNotification(notification);
      if (!value || bookmarked || unprocessed || processed) {
        bookmarked && !!value?.bookmarked && queryClient.refetchQueries({ queryKey: ['getNewsList', portal_slug, filtersQuery, date, language], exact: true });
        chekedArticles.forEach(({ keyword_id }) => {
          queryClient.refetchQueries({ queryKey: ['getCharts', portal_slug, filtersQuery, keyword_id], exact: true });
        });
      }
    },
    onSettled: () => {
      dispatch(actions.closeFooter());
      setTimeout(() => { dispatch(actions.clearChecked()); }, 1000);
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); }
  });
};

export const useDeleteManyArticles = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const language = useSelector(state => state?.userInfo?.language);
  const chekedArticles = useSelector(state => state?.news?.chekedArticles);
  const { filtersQuery, tabItem } = useSelector(state => state?.filters);
  const date = useSelector(state => state?.datePicker?.dateObj);

  return useMutation({
    mutationKey: 'deleteManyArticles',
    mutationFn: ({ is_recovery }) => {
      const body = chekedArticles?.map(({ article_id }) => ({ article_id, is_recovery }));
      return requests.deleteManyArticles({ portal_slug, body });
    },
    onSuccess: (_, req) => {
      dispatch(actions.deleteManyArticles());
      if (!req.is_recovery) {
        openNotification(req?.notification || { message: t('notif', 'MENTIONS_REMOVED', language) });
      }
      if (req.is_recovery) {
        sendFeedEvent({ category: 'News_Control_Panel', event: 'Delete_Mentions_Cancel' });
        openNotification({ message: t('notif', 'MENTIONS_RESTORED', language) });
        !tabItem?.action && queryClient.refetchQueries({ queryKey: ['getNewsList', portal_slug, filtersQuery, date, language], exact: true });
      }
      chekedArticles.forEach(({ keyword_id }) => {
        queryClient.refetchQueries({ queryKey: ['getCharts', portal_slug, filtersQuery, keyword_id], exact: true });
      });
    },
    onSettled: () => {
      dispatch(actions.closeFooter());
      setTimeout(() => { dispatch(actions.clearChecked()); }, 3000);
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); }
  });
};

export const useGetMoreArticles = () => {
  const dispatch = useDispatch();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const { filtersQuery } = useSelector(state => state?.filters);
  const language = useSelector(state => state?.userInfo?.language);

  return useMutation({
    mutationKey: 'getMoreArticles',
    mutationFn: ({ keyword_id, next_split, start_split }) => requests.getMoreArticles({ portal_slug, keyword_id, params: { ...filtersQuery, next_split, start_split } }),
    onSuccess: ({ articles, next, next_split, start_split }, { keyword_id }) => {
      dispatch(actions.addMoreNews({ articles, next_split: next || next_split, start_split, keyword_id }));
      dispatch(actions.resetNewsCount());
    },
    onError: (resp) => { openNotification({ message: `Error: ${resp.response.status}, ${t('notif', 'SOMETHING_WRONG', language)}` }); }
  });
};

export const useGetCharts = ({ id }) => {
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const filtersQuery = useSelector(state => state?.filters?.filtersQuery);
  return useQuery({
    queryKey: ['getCharts', portal_slug, filtersQuery, id],
    queryFn: () => requests.getCharts({ portal_slug, keyword_id: id, params: { ...filtersQuery } }),
    enabled: !!id
  });
};

export const useSetManyTags = () => {
  const dispatch = useDispatch();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const language = useSelector(state => state?.userInfo?.language);
  const isOpenModal = useSelector(state => state?.modals?.isOpen);
  return useMutation({
    mutationKey: 'setManyTags',
    mutationFn: ({ body }) => requests.setManyTags({ portal_slug, body }),
    onSuccess: (_, { body, isDetails }) => {
      dispatch(actions.addManyTags(body));
      const feedAdded = body?.tags?.length === 1 ? 'TAG_ADDED_ONE' : 'TAGS_ADDED';
      const detailsAdded = body?.tags?.length === 1 ? 'TAGS_ADDED_ARTICLE_ONE' : 'TAGS_ADDED_ARTICLE';
      const feed = body?.remove_tags?.length ? 'TAGS_CHANGED' : feedAdded;
      const detail = body?.remove_tags?.length ? 'TAGS_CHANGED_ARTICLE' : detailsAdded;
      const message = t('notif', isDetails ? detail : feed, language);
      openNotification({ message });
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); },
    onSettled: () => {
      isOpenModal && dispatch(actions.closeModal());
      dispatch(actions.closeFooter());
    }
  });
};

export const useRemoveManyTags = () => {
  const dispatch = useDispatch();
  const language = useSelector(state => state?.userInfo?.language);
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const isOpenModal = useSelector(state => state?.modals?.isOpen);
  return useMutation({
    mutationKey: 'removeManyTags',
    mutationFn: ({ body }) => requests.removeManyTags({ portal_slug, body }),
    onSuccess: (_, { body, isDetails }) => {
      if (!body?.tags?.length) {
        const feedRemoved = body?.remove_tags?.length === 1 ? 'TAGS_REMOVED_ONE' : 'TAGS_REMOVED';
        const detailsRemoved = body?.remove_tags?.length === 1 ? 'TAGS_REMOVED_ARTICLE_ONE' : 'TAGS_REMOVED_ARTICLE';
        const message = t('notif', isDetails ? detailsRemoved : feedRemoved, language);
        openNotification({ message });
      }
      dispatch(actions.removeManyTags(body));
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); },
    onSettled: () => {
      dispatch(actions.closeFooter());
      isOpenModal && dispatch(actions.closeModal());
    }
  });
};

export const useRemoveOnlyTag = () => {
  const dispatch = useDispatch();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const language = useSelector(state => state?.userInfo?.language);
  return useMutation({
    mutationKey: 'removeOnlyTags',
    mutationFn: ({ article_id, tag_id }) => requests.removeTag({ portal_slug, tag_id, body: { article_id } }),
    onSuccess: (_, { article_id, tag_id }) => { dispatch(actions.removeOnlyTag({ article_id, tag_id })); },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); }
  });
};

export const useGetArticleDetails = ({ enabled }) => {
  const dispatch = useDispatch();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const date = useSelector(state => state?.datePicker?.dateObj);
  const params = useParams();
  const article_id = params.articleId;
  const page_id = params.pageId;
  const language = useSelector(state => state?.userInfo?.language);
  const history = useNavigate();
  return useQuery({
    enabled,
    queryKey: ['getArticleDetails', portal_slug, article_id, date],
    queryFn: () => (article_id ? requests.getArticleDetails({ portal_slug, article_id }) : null),
    onSuccess: (resp) => { dispatch(actions.addArticleDetails(resp)); },
    onError: () => {
      const id = page_id === 'all' ? '' : `${article_id}/`;
      history(`/${portal_slug}/mentions/all/${id}`);
      openNotification({ message: t('notif', 'SOMETHING_WRONG', language) });
    }

  });
};

export const useEditArticleDetails = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const language = useSelector(state => state?.userInfo?.language);
  const { filtersQuery, tabItem } = useSelector(state => state?.filters);
  const date = useSelector(state => state?.datePicker?.dateObj);
  const { pageId, articleId } = useParams();
  const history = useNavigate();
  const processed = tabItem?.action === 'processed';
  const unprocessed = tabItem?.action === 'unprocessed';
  const bookmarked = tabItem?.action === 'bookmarked';
  return useMutation({
    mutationKey: 'editArticleDetails',
    cacheTime: 0,
    initialData: undefined,
    mutationFn: ({ body, article_id, keyword_id, keywords_id, action }) => {
      if (action === 'changeViewed') {
        return requests.addToViewed({ article_id });
      }
      if (action === 'changeProcessed') {
        return body?.processed ? requests.addToProcessed({ portal_slug, body: { article_id } }) : requests.removeFromProcessed({ portal_slug, id: article_id });
      }
      if (action === 'changeFavorite') {
        return body?.bookmarked ? requests.addToBookmark({ article_id }) : requests.removeManyFromBookmark({ article_ids: [article_id] });
      }
      if (action === 'changeTone') {
        return requests.editManyArticles({ portal_slug, body: [{ article_ids: [article_id], keyword_id, ...body }] });
      }
      if (action === 'changeToneDetails' || action === 'changeRole') {
        return requests.editArticleKeywordsDetails({ portal_slug, keywords_id, article_id, body });
      }
      return requests.editArticleDetails({ portal_slug, article_id, body });
    },
    onSuccess: (resp, { body, article_id, keyword_id, action }) => {
      if ((action === 'changeProcessed' && (processed || unprocessed)) || (action === 'changeFavorite' && bookmarked)) {
        dispatch(actions.deleteArticleDetails({ article_id, keyword_id }));
        articleId && history(`/${portal_slug}/mentions/${pageId || 'all'}`);
      } else if (action === 'setOriginal') {
        queryClient.refetchQueries({ queryKey: ['getNewsList', portal_slug, filtersQuery, date, language], exact: true });
      } else {
        dispatch(actions.changeArticle({ body, article_id, keyword_id, keywords_value: resp }));
      }
      action !== 'changeViewed' && queryClient.refetchQueries({ queryKey: ['getCharts', portal_slug, filtersQuery, keyword_id], exact: true });
      action === 'changeProcessed' && openNotification({ message: t('notif', body?.processed ? 'SOURCE_ADD_PROCESSED' : 'SOURCE_ADD_UNPROCESSED', language) });
      (action === 'changeTone' || action === 'changeToneDetails') && openNotification({ message: t('notif', 'TONE_CHANGED', language) });
      action === 'changeFavorite' && openNotification({ message: t('notif', body?.bookmarked ? 'ADDED_FAVORITE' : 'REMOVED_FAVORITE', language) });
      action === 'changeRole' && openNotification({ message: t('notif', 'ROLE_CHANGED', language) });
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); }
  });
};

export const useDeleteArticle = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const language = useSelector(state => state?.userInfo?.language);
  const { pageId } = useParams();
  return useMutation({
    mutationKey: 'deleteArticleDetails',
    mutationFn: ({ article_id, body }) => requests.deleteManyArticles({ portal_slug, body: [{ article_id, ...body }] }),
    onSuccess: (_, { article_id, keyword_id }) => {
      dispatch(actions.deleteArticleDetails({ article_id, keyword_id }));
      openNotification({ message: t('notif', 'MENTION_BEEN_REMOVED', language) });
      history(`/${portal_slug}/mentions/${pageId || 'all'}`);
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); }
  });
};

export const useSubscribeDublicates = () => {
  const dispatch = useDispatch();
  const language = useSelector(state => state?.userInfo?.language);
  return useMutation({
    mutationKey: 'subscribeDublicates',
    mutationFn: ({ body }) => requests.subscribeDublicates(body),
    onSuccess: (resp, { article_id, keyword_id }) => {
      const body = { alert_article_duplicate_setting: resp?.id };
      dispatch(actions.changeArticle({ body, article_id, keyword_id }));
      openNotification({ message: t('notif', 'SUBSCRIBED_DUBLICATES', language) });
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); }
  });
};

export const useUnsubscribeDublicates = () => {
  const dispatch = useDispatch();
  const language = useSelector(state => state?.userInfo?.language);
  return useMutation({
    mutationKey: 'unsubscribeDublicates',
    mutationFn: ({ subscription_id }) => requests.unsubscribeDublicates(subscription_id),
    onSuccess: (_, { article_id, keyword_id }) => {
      const body = { alert_article_duplicate_setting: null };
      dispatch(actions.changeArticle({ body, article_id, keyword_id }));
      openNotification({ message: t('notif', 'UNSUBSCRIBED', language) });
    },
    onError: ({ message, response }) => { openNotification({ message: response?.data?.detail || message }); }
  });
};

export const useGetSubscribeChoices = () => useQuery({
  queryKey: ['getSubscribeChoices'],
  queryFn: () => requests.getSubscribeChoices()
});

export const useDownloadReport = () => {
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const filtersQuery = useSelector(state => state?.filters?.filtersQuery);
  const { isOpenFooter, chekedArticles, chekedMentions } = useSelector(state => state?.news);
  const dispatch = useDispatch();
  const language = useSelector(state => state?.userInfo?.language);
  return useMutation({
    mutationKey: 'downloadReport',
    mutationFn: body => requests.downloadReport({ portal_slug, body: { ...filtersQuery, ...body } }),
    onSuccess: ({ message, url }) => {
      message && dispatch(actions.openModal({ action: 'downloadArticles', info: { message } }));
      !message && dispatch(actions.closeModal());
      url && window.open(url, '_blank');
      isOpenFooter && dispatch(actions.closeFooter());
      (chekedArticles?.length || chekedMentions?.length) && dispatch(actions.clearChecked());
    },
    onError: ({ message, response }) => {
      if (response?.data?.status === 'task created') {
        dispatch(actions.closeModal());
        dispatch(actions.openModal({ action: 'duplicateReport' }));
      } else {
        const defaultMessage = t('notif', 'SOMETHING_WRONG', language);
        const messageError = typeof message === 'string' && message;
        const detailError = typeof response?.data?.detail === 'string' && response.data.detail;
        openNotification({
          message: detailError || messageError || defaultMessage
        });
        dispatch(actions.closeModal());
      }
    }
  });
};

export const useDownloadReportsList = () => {
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  return useQuery({
    queryKey: ['downloadReportsList', portal_slug],
    queryFn: () => requests.downloadReportList({ portal_slug }),
    onError: ({ message, response }) => { openNotification({ message: response?.data?.detail || message }); }
  });
};

export const useBlockSources = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const language = useSelector(state => state?.userInfo?.language);
  const isOpenModal = useSelector(state => state?.modals?.isOpen);
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const filtersQuery = useSelector(state => state?.filters?.filtersQuery);
  const date = useSelector(state => state?.datePicker?.dateObj);
  return useMutation({
    mutationKey: 'blockSource',
    mutationFn: ({ body }) => {
      if (body?.publication_place_ids) return requests.blockPublicationPlaces({ portal_slug, body });
      if (body?.creator_ids) return requests.blockCreators({ portal_slug, body });
      return requests.blockSources({ portal_slug, body });
    },
    onSuccess: (_, { body }) => {
      body?.source_ids && openNotification({ message: t('notif', 'SOURCE_BLOCKED', language) });
      body?.publication_place_ids && openNotification({ message: t('notif', 'PLACE_BLOCKED', language) });
      body?.creator_ids && openNotification({ message: t('notif', 'CREATOR_BLOCKED', language) });
      queryClient.refetchQueries({ queryKey: ['getNewsList', portal_slug, filtersQuery, date, language], exact: true });
      queryClient.refetchQueries({ queryKey: ['getSettings', portal_slug, language], exact: true });
      queryClient.refetchQueries({ queryKey: ['getNewsAllCount', portal_slug, filtersQuery, date], exact: true });
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); },
    onSettled: () => { isOpenModal && dispatch(actions.closeModal()); }
  });
};

export const useUnblockSources = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const language = useSelector(state => state?.userInfo?.language);
  const isOpenModal = useSelector(state => state?.modals?.isOpen);
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const filtersQuery = useSelector(state => state?.filters?.filtersQuery);
  const date = useSelector(state => state?.datePicker?.dateObj);
  return useMutation({
    mutationKey: 'unblockSource',
    mutationFn: ({ body }) => {
      if (body?.publication_place_ids) return requests.unblockPublicationPlaces({ portal_slug, body });
      if (body?.creator_ids) return requests.unblockCreators({ portal_slug, body });
      return requests.unblockSources({ portal_slug, body });
    },
    onSuccess: (_, { body }) => {
      body?.source_ids && openNotification({ message: t('notif', 'SOURCE_UNBLOCKED', language) });
      body?.publication_place_ids && openNotification({ message: t('notif', 'PLACE_UNBLOCKED', language) });
      body?.creator_ids && openNotification({ message: t('notif', 'CREATOR_UNBLOCKED', language) });
      queryClient.refetchQueries({ queryKey: ['getNewsList', portal_slug, filtersQuery, date, language], exact: true });
      queryClient.refetchQueries({ queryKey: ['getSettings', portal_slug, language], exact: true });
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); },
    onSettled: () => { isOpenModal && dispatch(actions.closeModal()); }
  });
};

export const useChangeAllViewed = () => {
  const queryClient = useQueryClient();
  const language = useSelector(state => state?.userInfo?.language);
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const filtersQuery = useSelector(state => state?.filters?.filtersQuery);
  const date = useSelector(state => state?.datePicker?.dateObj);
  return useMutation({
    mutationKey: 'changeAllViewed',
    mutationFn: () => requests.addAllToViewed({ portal_slug, body: { filter: filtersQuery } }),
    onSuccess: () => {
      openNotification({ message: t('notif', 'SOURCES_VIEWED', language) });
      queryClient.refetchQueries({ queryKey: ['getNewsList', portal_slug, filtersQuery, date, language], exact: true });
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); }
  });
};

export const useSendComplaints = () => {
  const language = useSelector(state => state?.userInfo?.language);
  return useMutation({
    mutationKey: 'sendComplaints',
    mutationFn: body => requests.sendComplaints(body),
    onSuccess: () => { openNotification({ message: t('notif', 'COMPLAINT_SENT', language) }); },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); }
  });
};

export const useGetCardInfo = ({ enabled, model_key, id }) => {
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const params = useSelector(state => state?.filters?.filtersQuery);
  return useQuery({
    enabled,
    queryKey: ['getCardInfo', portal_slug, model_key, id, params],
    queryFn: () => requests.getCardInfo({ id, model_key, portal_slug, params })
  });
};

export const useTranslateArticle = ({ enabled }) => {
  const { articleId } = useParams();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  return useQuery({
    enabled,
    queryKey: ['translateArticle', portal_slug, articleId],
    queryFn: () => requests.translateArticle({ id: articleId, portal_slug })
  });
};

export const useCreateQuery = () => {
  const language = useSelector(state => state?.userInfo?.language);
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  return useMutation({
    mutationKey: 'createQuery',
    mutationFn: ({ body, filterName }) => requests.createQuery({ body, portal_slug, filterName }),
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); }
  });
};

export const useCreateAlertQuery = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector(state => state?.userInfo?.language);
  return useMutation({
    mutationKey: 'createQuery',
    mutationFn: queryBuilder => requests.createQueryForAlert(queryBuilder),
    onSuccess: (resp) => {
      dispatch(actions.closeModal());
      history('/advanced_builder/alert/', { state: { query: resp.query } });
    },
    onError: () => {
      dispatch(actions.closeModal());
      history('/advanced_builder/alert/');
      openNotification({ message: t('notif', 'SOMETHING_WRONG', language) });
    }
  });
};

export const useComplaintArticle = () => {
  const dispatch = useDispatch();
  const language = useSelector(state => state?.userInfo?.language);
  const isOpenModal = useSelector(state => state?.modals?.isOpen);
  return useMutation({
    mutationKey: 'complaintArticle',
    mutationFn: body => requests.complaintArticle(body),
    onSuccess: () => { openNotification({ message: t('notif', 'CHECK_REPORT', language) }); },
    onError: ({ message, response }) => { openNotification({ message: response?.data?.detail || message }); },
    onSettled: () => { isOpenModal && dispatch(actions.closeModal()); }
  });
};
