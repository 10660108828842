/* eslint-disable no-unused-vars */
/* eslint-disable no-inner-declarations */
import { useEffect, useState, Suspense, useCallback, useRef, lazy } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import t from 'utils/translateFunc';
import {
  onChangeTab as onChangeTabAction, addFilterItem, openModal, changeNewsCount, clearArticleDetails, clearFilterList,
  clearCurrentPage, onChangeKeywordId, openCopilotWindow
} from 'storage/actions';
import cn from 'classnames';
import { NoMentions, openNotification, Icon, SkeletonNavPage, SkeletonSelectedFiltersPanel, SkeletonMention, Icons,
  ErrorBoundarySentry, Maintenance, AnimatePage
} from 'Components';
import colors from 'styles/_colors.scss';
import {
  useGetNewsList,
  useGetSettings,
  useRemoveOnlyTag,
  useEditArticleDetails,
  useBlockSources,
  useDeleteArticle,
  useGetPages,
  useGetPageId,
  useSubscribeDublicates,
  useUnsubscribeDublicates,
  useGetUserInfo,
  useCheckMoreNews
} from 'storage/queryHooks';
import { COPILOT, PROJECT } from 'config';
import Modals from 'Containers/Modals';
import { useCheckMobileScreen } from 'hooks/useCheckMobileScreen';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './Mentions.module.scss';
import MentionsList from './MentionsList';

const NavPage = lazy(() => import('./NavPage'));
const SelectedFiltersPanel = lazy(() => import('./SelectedFiltersPanel'));
const Details = lazy(() => import('./Details'));
const FiltrationPanel = lazy(() => import('./FiltrationPanel'));
const QInsights = lazy(() => import('./Copilot'));
export default function Mentions() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { pageId, articleId } = useParams();
  const detailsRef = useRef();
  const gutterRef = useRef();
  const mainRef = useRef();
  const userInfo = useGetUserInfo();
  const { isLoading: pagesLoading } = useGetPages();
  const { isLoading: pageLoading } = useGetPageId();
  const settings = useGetSettings();
  const dataCheckMoreNews = useCheckMoreNews();
  const { isLoading: newsLoading, isError: isErrorNews, status: newsStatus, isRefetching: newsRefetching, refetch: onReloadNews } = useGetNewsList();
  const { mutateAsync: onRemoveOneTag } = useRemoveOnlyTag();
  const { mutateAsync: onEditArticle } = useEditArticleDetails();
  const { mutateAsync: onDeleteArticle } = useDeleteArticle();
  const { mutateAsync: subscribeDublicates } = useSubscribeDublicates();
  const { mutateAsync: unSubscribeDublicates } = useUnsubscribeDublicates();
  const { mutateAsync: blockSources } = useBlockSources();

  const [showArrowUp, setShowArrowUp] = useState(false);
  const [filtersPanelVisible, setFiltersPanelVisible] = useState(false);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [qInsightsVisible, setQInsightsVisible] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);
  const { language } = useSelector(state => state?.userInfo);
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const { filterList, tabItem } = useSelector(state => state?.filters);
  const { newsList, count } = useSelector(state => state?.news);
  const isMobile = useCheckMobileScreen();
  const serverUpdating = useSelector(state => state?.auth?.serverUpdating);
  const copilot_is_open = useSelector(state => state?.copilot?.is_open);

  useEffect(() => {
    if (COPILOT) {
      setQInsightsVisible(copilot_is_open);
      if (copilot_is_open) setDetailsVisible(false);
    }
  }, [copilot_is_open]);

  useEffect(() => {
    const mentionsContent = document.getElementById('mentions-content');
    if (newsList?.length && mentionsContent) {
      mentionsContent.clientHeight < mentionsContent.scrollHeight && !showArrowUp && setShowArrowUp(true);
      mentionsContent.clientHeight >= mentionsContent.scrollHeight && showArrowUp && setShowArrowUp(false);
    }
    !newsList?.length && showArrowUp && setShowArrowUp(false);
  }, [newsList?.length]);

  useEffect(() => {
    if (!articleId && detailsVisible) {
      setDetailsVisible(false);
    }
    if (articleId && !detailsVisible) {
      setDetailsVisible(true);
    }
    hasMounted && articleId && sendFeedEvent({ category: 'Article', event: 'Open_Full_Article' });
    !hasMounted && setHasMounted(true);
  }, [articleId]);

  useEffect(() => {
    if (!tabItem) {
      dispatch(onChangeTabAction({ name: t('tabsMenu', 'ALL_MENTIONS', language), icon: '', action: '' }));
    }
  }, [tabItem]);

  useEffect(() => {
    setTimeout(moveChatLauncher, 400);
    if (detailsVisible) {
      const detailsStyleWidth = detailsRef?.current?.style.width || '470px';
      const detailsWidth = parseInt(detailsStyleWidth, 10);
      mainRef.current.style.width = `calc(100% - ${detailsWidth}px)`;
      hideQInsights();
      return;
    }
    (!detailsVisible && !qInsightsVisible) && mainRef.current && (mainRef.current.style.width = '100%');
  }, [detailsVisible, qInsightsVisible]);

  function hideQInsights() {
    dispatch(openCopilotWindow({ is_open: false }));
  }

  function moveChatLauncher() {
    const details_width = document.getElementById('mentions_details')?.offsetWidth || 0;

    const chat = document.getElementById('intercom-container');
    if (chat) {
      const els = chat.querySelector('div.intercom-app').children;
      for (let i = 0; i < els.length; i += 1) {
        els[i].style.right = `calc(20px + ${details_width}px)`;
      }
    }
    const chat_mod = document.getElementsByClassName('intercom-lightweight-app');
    if (chat_mod?.length) {
      const els = chat_mod[0].querySelector('div');
      els.style.right = `calc(20px + ${details_width}px)`;
    }
  }

  useEffect(() => {
    const mainComp = mainRef.current;
    if (detailsVisible) {
      const detailsStyleWidth = detailsRef?.current?.style.width || '470px';
      const detailsWidth = parseInt(detailsStyleWidth, 10);
      mainComp && (mainComp.style.width = `calc(100% - ${detailsWidth}px)`);
      return;
    }
    !detailsVisible && mainComp && (mainComp.style.width = '100%');
  }, [detailsVisible]);

  function handleScrollToTop() {
    sendFeedEvent({ category: 'Newsfeed', event: 'Clicked_Up_Button' });
    const elem = document.getElementById('mentions-content');
    elem.scrollTo({ top: 0, behavior: 'smooth' });
  }

  function onChangeTab(value) {
    dispatch(onChangeTabAction(value));
    if (articleId) { history(`/${portal_slug}/mentions/${pageId || 'all'}`); }
  }
  function onHideDetails() {
    setDetailsVisible(false);
    hideQInsights();
    history(`/${portal_slug}/mentions/${pageId || 'all'}`);
    dispatch(clearArticleDetails());
  }

  function onRemoveTag({ tag_id, article_id }) {
    onRemoveOneTag({ tag_id, article_id });
  }

  function onSubmit({ action, info, body }) {
    const createEventName = () => {
      const baseName = 'article_link_';
      if (action === 'changeToneDetails' && body?.tone) return `${baseName}${body.tone}_${action}`;
      if (action === 'changeRole' && body?.role) return `${baseName}${body.role}_${action}`;
      return `${baseName}${action}`;
    };

    const eventActions = ['changeRole', 'changeToneDetails', 'reportProblem', 'showCard', 'copyLink'];
    const eventWithoutBlockList = ['blockSource', 'filterMentions'];
    (eventActions.includes(action) || (eventWithoutBlockList.includes(action) && !body)) && sendFeedEvent({ category: 'Full text', event: createEventName() });

    if (action === 'showCard' || action === 'reportProblem') {
      dispatch(openModal({ action, info }));
    }
    if (action === 'filterMentions') {
      const filterSource = {
        id: info?.source?.id || info?.source_id,
        name: info?.source?.name || info?.source,
        type: t('filtrationPanel', 'SOURCE', language),
        filterName: 'source'
      };
      const filterPublicationPlace = {
        id: info?.publication_place_subscribers_id || info?.publication_place?.id,
        name: info?.publication_place_name || info?.publication_place?.name,
        type: t('filtrationPanel', 'PUBLICATION_PLACE', language),
        filterName: 'publication_place'
      };
      const filterCreator = {
        id: info?.creators[0]?.id || null,
        name: info?.creators[0]?.name || null,
        type: t('filtrationPanel', 'CREATOR', language),
        filterName: 'creator'
      };
      function getFilterItemData() {
        if (body?.name === 'publication_place') {
          return filterPublicationPlace;
        }
        if (body?.name === 'creator') {
          return filterCreator;
        }
        return filterSource;
      }
      const filterItemData = getFilterItemData();

      dispatch(addFilterItem(filterItemData));
    }
    if (action === 'blockSource') {
      const bodyBlock = {};
      !body && (bodyBlock.source_ids = [info?.source_id || info?.source?.id]);
      body?.name === 'creator' && (bodyBlock.creator_ids = [info?.creators[0]?.id || null]);
      body?.name === 'publication_place' && (bodyBlock.publication_place_ids = [info?.publication_place_subscribers_id || null]);
      blockSources({ body: bodyBlock });
      onHideDetails();
    }
    if (action === 'copyLink') {
      let link = info?.url || '';
      info?.media_type === 'internet' && (link = info?.internetarticle?.url || info?.url);
      info?.media_type === 'agency' && (link = info?.agencyarticle?.url || info?.url);
      info?.media_type === 'press' && (link = info?.pressarticle?.url || info?.url);
      info?.media_type === 'radio' && (link = info?.radioarticle?.audio_url || info?.url);
      info?.media_type === 'socialnetwork' && (link = info?.socialnetworkarticle?.url || info?.url);
      info?.media_type === 'forum' && (link = info?.forumarticle?.url || info?.url);
      info?.media_type === 'review' && (link = info?.reviewarticle?.url || info?.url);
      info?.media_type === 'tv' && (link = info?.tvarticle?.video_url || info?.url);
      navigator.clipboard
        .writeText(link)
        .then(() => {
          openNotification({ message: t('notif', 'COPY_LINK', language) });
        });
      return;
    }
    const editArticleActions = ['changeTone', 'changeToneDetails', 'changeProcessed', 'changeFavorite', 'changeRole', 'setOriginal'];
    if (editArticleActions.includes(action)) {
      onEditArticle({ keyword_id: info?.keyword_id, keywords_id: info?.keywords_id, article_id: info?.id, body, action });
      return;
    }
    if (action === 'changeSubscription') {
      body?.subscription_id
        ? unSubscribeDublicates({ subscription_id: body?.subscription_id, keyword_id: info?.keyword_id, article_id: info?.id })
        : subscribeDublicates({ body, keyword_id: info?.keyword_id, article_id: info?.id });
      return;
    }
    if (action === 'deleteArticle') {
      onDeleteArticle({ keyword_id: info?.keyword_id, article_id: info?.id, body });
      setDetailsVisible(false);
    }
  }

  function onVisible({ article_id, keyword_id, viewed }) {
    if (!detailsVisible) {
      setDetailsVisible(!detailsVisible);
    }
    !viewed && onEditArticle({ keyword_id, article_id, body: { viewed: true }, action: 'changeViewed' });
    dispatch(onChangeKeywordId(keyword_id));
    history(`/${portal_slug}/mentions/${pageId || 'all'}/${article_id}`);
  }

  function onShowMoreMentions() {
    onReloadNews();
    dispatch(changeNewsCount({ count: 0 }));
  }

  function navigateToPage(page_id) {
    if (page_id === 'all') {
      dispatch(clearFilterList());
      dispatch(clearCurrentPage());
    }
    if (pageId !== String(page_id)) {
      history(`/${portal_slug}/mentions/${page_id}`);
    }
  }

  const onResizePanel = useCallback(
    (event) => {
      event.preventDefault();
      const prevPositionX = event.clientX;
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
      const panelWidth = Number(detailsRef.current.style.width.replace(/[^.\d]+/g, '')) || 470;

      function onMouseMove(e) {
        if (e.clientX) {
          const currentPositionX = e.clientX;
          const transitionPosition = prevPositionX - currentPositionX;
          let widthParam = panelWidth + transitionPosition;
          const mainWidth = mainRef.current?.clientWidth;
          widthParam < 470 && (widthParam = 470);
          widthParam > mainWidth && (widthParam = mainWidth);
          detailsRef.current.style.transitionDuration = '150ms';
          detailsRef.current.style.width = `${widthParam}px`;
          mainRef.current.style.transitionDuration = '150ms';
          mainRef.current.style.width = `calc(100% - ${widthParam}px)`;
          moveChatLauncher();
        }
      }
      function onMouseUp() {
        detailsRef.current.style.transitionDuration = '350ms';
        mainRef.current.style.transitionDuration = '350ms';
        detailsRef.current.style.filter = 'none';
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mouseup', onMouseUp);
        setTimeout(moveChatLauncher, 200);
      }
    },
    []
  );

  const hideFilterPanel = () => {
    sendFeedEvent({ category: 'Filters', event: 'Clicked_Back_Arrow_Button_To_Close_Filters_Panel' });
    setFiltersPanelVisible(false);
  };

  return (
    <AnimatePage>
      {!serverUpdating ? (
        <div className={classes.wrapper}>
          <div className={cn(classes.main, detailsVisible && classes.main_detailsOn)} id="mentions-main" ref={mainRef}>
            <Suspense fallback={<SkeletonNavPage fetching />}>
              <SkeletonNavPage fetching={pagesLoading}>
                {(!isMobile || (isMobile && !detailsVisible)) && (
                <NavPage
                  detailsVisible={detailsVisible}
                  onChangeTab={onChangeTab}
                  active={tabItem?.name}
                  navigateToPage={navigateToPage}
                />
                )}
              </SkeletonNavPage>
            </Suspense>
            <Suspense fallback={<SkeletonSelectedFiltersPanel />}>
              <SelectedFiltersPanel
                setFiltersPanelVisible={setFiltersPanelVisible}
                setDetailsVisible={setDetailsVisible}
                filtersPanelVisible={filtersPanelVisible}
                detailsVisible={detailsVisible}
                pageLoading={pageLoading}
                newsLoading={newsLoading || newsRefetching}
              />
            </Suspense>
            <div className={cn(classes.contentWrapper, filterList.length && classes.contentWrapper_size)}>
              {!isMobile && (detailsVisible || qInsightsVisible) && (
              <button
                className={cn(classes.hideBtn)}
                onClick={() => {
                  sendFeedEvent({ category: 'Full text', event: 'Clicked_Right_Button' });
                  onHideDetails();
                }}
                type="button"
              >
                <Icons icon="Icn_ArrowCircle" className={classes.icon} />
              </button>
              )}
              { !!count && (
              <Icon
                className={classes.iconShowNew}
                type="arrow_circle"
                text={(
                  <>
                    <b>{count}</b>
                    {t('mentions', 'NEW_MENTIONS', language)}
                  </>
        )}
                onClick={onShowMoreMentions}
              />
              )}
              <div className={cn(classes.filter, filtersPanelVisible && classes.filter_activeFilterPanel)}>
                <Suspense fallback={<span />}>
                  <FiltrationPanel visible={filtersPanelVisible} onClick={hideFilterPanel} />
                </Suspense>
              </div>
              <div className={cn(classes.interlayer, filtersPanelVisible && classes.interlayer_activeFilterPanel)} />
              <SkeletonMention fetching={newsLoading || newsRefetching || newsStatus === 'idle'} className={cn(filtersPanelVisible && classes.skeleton_activeFilterPanel)}>
                {newsList?.length ? (
                  <MentionsList
                    onVisible={onVisible}
                    onRemoveTag={onRemoveTag}
                    onSubmit={onSubmit}
                    filtersPanelVisible={filtersPanelVisible}
                  />
                ) : <NoMentions isError={isErrorNews} onReloadNews={onReloadNews} />}
              </SkeletonMention>
              <SkeletonMention />
              {showArrowUp && (
              <Icon
                type="arrow_up"
                color={PROJECT === 'metricom' ? colors.green700m : colors.pink400}
                onClick={handleScrollToTop}
                className={cn(classes.topButtonWrapper)}
              />
              )}
            </div>
            {(detailsVisible || qInsightsVisible) && (
            <div ref={gutterRef} className={classes.gutterLine} onMouseDown={onResizePanel} />
            )}
          </div>
          <div id="mentions_details" className={cn(classes.details, (detailsVisible || qInsightsVisible) && classes.details_activeDetails)} ref={detailsRef}>
            <Suspense fallback={<span />}>
              <ErrorBoundarySentry language={language} className={classes.detailsError}>
                {detailsVisible && (
                  <Details
                    visible={detailsVisible}
                    onRemoveTag={onRemoveTag}
                    onSubmit={onSubmit}
                    onHide={onHideDetails}
                  />
                )}
                {(qInsightsVisible && !detailsVisible) && (
                  <QInsights onHide={hideQInsights} />
                )}
              </ErrorBoundarySentry>
            </Suspense>
          </div>
          <Modals navigateToPage={navigateToPage} />
        </div>
      )
        : <Maintenance />}
    </AnimatePage>
  );
}
