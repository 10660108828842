import { useRef, useMemo, memo } from 'react';
import cn from 'classnames';
import { wordToHexColor } from 'utils/common';
import t from 'utils/translateFunc';
import { Tooltip, TooltipForTruncate, Icon } from 'Components';
import { shape, func, bool } from 'prop-types';
import { Trans } from 'react-i18next';
import { PROJECT } from 'config';
import classes from './ReportCard.module.scss';
import { optionsCommon, replaceWeekDaysByName } from '../options.js';

const ReportCard = memo(({ data = {}, userContacts = null, isSelected = false, isEnabled = true, onEditReport = () => null }) => {
  const cardRef = useRef();
  const footerRef = useRef();
  const { periodicitiesOptions, periodicityFrequency, nHoursText } = useMemo(() => optionsCommon(), []);
  const editable = !data?.editable && !!data?.id;
  const userTelegramAccounts = userContacts.telegram_accounts;
  const userWhatsappAccounts = userContacts.whatsapp_accounts;
  const userEmail = userContacts?.email;
  const includEmail = useMemo(() => ((userEmail && data?.emails?.length && data?.emails.includes(userEmail)) ? [userEmail] : []), [data?.emails, userEmail]);
  const includTelegramAccount = useMemo(() => ((data?.telegram_users?.length && userTelegramAccounts.length) ? data.telegram_users.reduce((acc, curr) => {
    const includeAccount = userTelegramAccounts?.find(el => el?.telegram_username === curr?.telegram_username);
    includeAccount && acc.push(includeAccount);
    return acc;
  }, []) : []), [data?.telegram_users, userTelegramAccounts]);
  const includWhatsappAccount = useMemo(() => ((data?.whatsapp_accounts?.length && userWhatsappAccounts.length) ? data.whatsapp_accounts.reduce((acc, curr) => {
    const includeAccount = userWhatsappAccounts?.find(el => el?.phone === curr?.phone);
    includeAccount && acc.push(includeAccount);
    return acc;
  }, []) : []), [data?.telegram_users, userTelegramAccounts]);
  const emails = editable ? includEmail : (data?.emails || []);
  const telegrams = editable ? includTelegramAccount : (data?.telegram_users || []);
  const whatspps = editable ? includWhatsappAccount : (data?.whatsapp_accounts || []);

  const senders = (data?.delivery_type === 'telegram' ? telegrams : null) || (data?.delivery_type === 'whatsapp' ? whatspps : null) || emails;
  const senderEmailsSymbolsLenght = useMemo(() => (emails?.length ? emails?.reduce((acc, curr) => acc += curr.trim().length, 0) : 0), [emails]);
  const senderTelegramSymbolsLenght = useMemo(
    () => (telegrams?.length ? telegrams?.reduce((acc, curr) => acc += curr.telegram_username.trim().length, 0) : 0),
    [telegrams]
  );
  const senderWhatsappSymbolsLenght = useMemo(
    () => (whatspps?.length ? whatspps?.reduce((acc, curr) => acc += curr.phone.length, 0) : 0),
    [whatspps]
  );
  // const senderTotalSymbols = data?.delivery_type === 'telegram' ? senderTelegramSymbolsLenght : senderEmailsSymbolsLenght;
  const senderTotalSymbols = (data?.delivery_type === 'telegram' ? senderTelegramSymbolsLenght : null) || (data?.delivery_type === 'whatsapp' ? senderWhatsappSymbolsLenght : null) || senderEmailsSymbolsLenght;
  const allKeyword = (data?.id && !data?.keyword?.id && !data?.page?.id) ? t('reports', 'ALL_OBJECTS') : '';
  const keywordName = data?.keyword?.name || allKeyword;
  const pageName = data?.page?.name || '';
  const templateName = data?.report_customization?.id && data?.report_customization?.id !== 'default' ? (data?.report_customization?.title || data?.report_customization?.name) : '';

  const reportType = (data?.report_type === 'report_html' ? 'html' : data?.report_type) || 'html';
  const periodicityValue = data?.frequency ? periodicityFrequency[data.frequency] : periodicityFrequency.default;
  const nHoursFrequency = data?.frequency === 'one_hour' || data?.frequency === 'two_hours' || data?.frequency === 'four_hours';
  const shortFrequency = useMemo(() => (periodicityValue ? periodicitiesOptions.find(item => item?.value === periodicityValue)?.label : ''), [data?.frequency]);
  const timeList = data?.time_list?.length && data.time_list;
  const hasDayTimeList = data?.frequency === 'multiple_times_per_day' && !!timeList?.length;
  const hasWeekTimeList = data?.frequency === 'multiple_per_week' && !!timeList?.length;
  const hasMonthTimeList = data?.frequency === 'multiple_per_month' && !!timeList?.length;
  const supportEmail = PROJECT === 'metricom' ? 'support@metricom' : 'support@looqme.ua';
  const cardOwner = data?.user?.username || supportEmail;
  const languageValue = {
    uk: 'UA',
    ru: 'RU',
    en: 'ENG',
    ro: 'RO',
    default: 'UA'
  };

  const daysGroup = useMemo(
    () => (timeList?.length ? Object.entries(timeList.reduce((acc, { day, time }) => ({ ...acc, [day]: [...(acc[day] || []), time] }), {})) : ''),
    [data?.frequency, timeList]
  );

  return (
    <div
      className={cn(classes.reportsCard, isSelected && classes.reportsCard_selected, !isEnabled && classes.reportsCard_disabled)}
      ref={cardRef}
      onClick={(e) => {
        if (e.target.dataset.checkbox !== 'switcher') {
          e.stopPropagation();
          !!data?.editable && onEditReport({ report_data: data, isToggle: false });
        }
      }}
    >
      <Tooltip
        text={data?.editable === false ? (<Trans i18nKey="notEditableReport" values={{ email: cardOwner }} />) : ''}
        trigger="click"
        className={classes.reportsCard_tooltip}
      >
        <div className={classes.reportBody}>

          <div className={classes.textWrap}>
            <div className={classes.textTitle}>
              {editable && <div className={classes.supportImage} />}
              <Tooltip
                text={data?.name?.length > 20 ? data?.name : ''}
                truncate
              >
                {data?.name || t('reports', data?.id ? 'NO_NAME' : 'REPORT_NAME')}
              </Tooltip>
            </div>
            <div className={cn(classes.textSubTitle, (keywordName || pageName).length && classes.textSubTitle_black)}>
              <Tooltip
                text={(keywordName || pageName).length > 35 ? (keywordName || pageName) : ''}
                truncate
              >
                {keywordName || pageName || t('reports', 'CHOOSE_OBJECT')}
              </Tooltip>
            </div>
            <div className={classes.textSmallSubTitle}>
              {t('reports', 'TEMPLATE')}
            </div>
            <div className={cn(classes.textSubTitle, (templateName || !!data?.id) && classes.textSubTitle_purple)}>
              <Tooltip
                text={templateName?.length > 35 ? templateName : ''}
                truncate
              >
                {templateName || t('reports', data?.id ? 'DEFAULT' : 'CHOOSE_TEMPLATE')}
              </Tooltip>
            </div>
            <div className={classes.textSmallSubTitle}>
              {t('reports', 'PERIODICITY')}
              {!!shortFrequency && <span>{` - ${shortFrequency}`}</span>}
            </div>
            <div className={classes.timeListWrap}>
              {nHoursFrequency && <>{nHoursText[data.frequency]}</>}
              {data?.frequency === 'multiple_times_per_day' && <>{t('reports', 'AT')}</>}
              {hasDayTimeList && timeList.map(({ time }, i, arr) => `${i === arr.length - 1 ? '' : ','} ${time}`).join('').replace(/^, /, '')}
              {hasWeekTimeList && (
                <TooltipForTruncate
                  rows={2}
                  tooltipContent={daysGroup?.map(([day, times], i, arr) => (
                    <div key={day}>
                      <div>{replaceWeekDaysByName(+day)}</div>
                      <div>{times.join(', ')}</div>
                      {i === arr.length - 1 ? '' : <br />}
                    </div>
                  ))}
                >
                  {daysGroup?.map(([day, times]) => `${replaceWeekDaysByName(+day)} ${times.join(', ')}`).join('; ')}
                </TooltipForTruncate>
              )}
              {hasMonthTimeList && (
              <TooltipForTruncate
                rows={2}
                tooltipContent={daysGroup?.map(([day, times], i, arr) => (
                  <div key={day}>
                    <div>
                      <Trans i18nKey="dayAt" values={{ day: +day }} />
                    </div>
                    <div>{times.join(', ')}</div>
                    {i === arr.length - 1 ? '' : <br />}
                  </div>
                ))}
              >
                {daysGroup?.map(([day, times]) => <Trans key={day} i18nKey="dayAtHours" values={{ day: +day, hours: times.join(', ') }} />)}
              </TooltipForTruncate>
              )}
            </div>
          </div>
          <div className={cn(classes.switchWrapper, editable && classes.switchWrapper_support)}>
            <div className={classes.formatLangWrapper}>
              <div className={classes.formatType}>
                <Icon type={reportType} />
                <span className={cn(
                  classes.formatTypeLabel,
                  reportType === 'pdf' && classes.formatTypeLabel_pdf,
                  reportType === 'xls' && classes.formatTypeLabel_xls,
                  reportType === 'docx' && classes.formatTypeLabel_docx,
                  reportType === 'html' && classes.formatTypeLabel_html
                )}
                >
                  {reportType?.toUpperCase()}
                </span>
              </div>
              <div className={cn(
                classes.language,
                data?.template_language === 'uk' && classes.language_ua,
                data?.template_language === 'en' && classes.language_eng,
                data?.template_language === 'ru' && classes.language_ru
              )}
              >
                {data?.template_language ? languageValue[data.template_language] : 'UA'}
              </div>
            </div>
            <label className={cn(classes.switch)}>
              <input
                data-checkbox="switcher"
                type="checkbox"
                onChange={() => {
                  data?.id && (data?.emails?.length || data?.telegram_users?.length || data?.whatsapp_accounts?.length) && onEditReport({ report_data: data, isToggle: true });
                }}
                checked={isEnabled}
              />
              <span data-checkbox="switcher" className={cn(classes.slider, classes.round)} />
            </label>
          </div>
        </div>
        <div className={classes.reportFooter} ref={footerRef}>
          {senders?.length ? senders.map(item => (
            <div key={item?.id || item} className={classes.postItem}>
              <Tooltip text={senderTotalSymbols > 35 ? item?.telegram_username || item?.phone || item : ''}>
                {item?.telegram_username && <div className={classes.telegramImage} />}
                {item?.phone && <div className={classes.whatsappImage} />}
                {typeof item === 'string' &&
                  <span className={cn(classes.postAvar)} style={{ backgroundColor: wordToHexColor(item) }}>{item.trim().charAt(0).toUpperCase()}</span>}
              </Tooltip>
              {senderTotalSymbols <= 35 &&
                <span className={classes.postText}>{item?.telegram_username?.trim() || item?.phone || item?.trim()}</span>}
            </div>
          )) : (
            <div className={classes.postItem}>
              <span className={classes.postText} />
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  );
});

ReportCard.propTypes = {
  data: shape({}),
  userContacts: shape({}),
  isEnabled: bool,
  isSelected: bool,
  onEditReport: func
};

export default ReportCard;
