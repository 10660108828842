import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import { COPILOT } from 'config';
import * as requests from './requests';
import { setCopilotInProcess, addCopilotHistory, setCopilotNextPage, deleteCopilotHistory, openModal, setCopilotUnread, cleanAllCopilotHistory } from '../actions';
import { openNotification } from '../../Components';

export const useCopilotCreate = () => {
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const filtersQuery = useSelector(state => state?.filters?.filtersQuery);
  const dispatch = useDispatch();
  return useMutation({
    mutationKey: 'copilotCreate',
    mutationFn: ({ body }) => requests.copilotCreate({ portal_slug, body: { ...filtersQuery, ...body } }),
    onSuccess: (resp) => {
      dispatch(setCopilotInProcess({ in_process: resp?.id }));
      const item = { ...resp };
      delete item?.messages;
      dispatch(addCopilotHistory({ history: [item] }));
    }
  });
};

export const useCopilotNewQuestion = () => {
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const filtersQuery = useSelector(state => state?.filters?.filtersQuery);
  const dispatch = useDispatch();
  return useMutation({
    mutationKey: 'copilotNewQuestion',
    mutationFn: ({ chat_id, body }) => requests.copilotNewQuestion({ portal_slug, chat_id, body: { ...filtersQuery, ...body } }),
    onSuccess: resp => dispatch(setCopilotInProcess({ in_process: resp?.id }))
  });
};

export const useCopilotDetail = () => {
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  return useMutation({
    mutationKey: 'copilotDetail',
    mutationFn: ({ chat_id }) => requests.copilotDetail({ portal_slug, chat_id })
  });
};

export const useCopilotHistory = () => {
  const dispatch = useDispatch();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  return useQuery({
    queryKey: ['copilotHistory', portal_slug],
    queryFn: () => requests.copilotHistory({ portal_slug }),
    enabled: COPILOT,
    onSuccess: (resp) => {
      dispatch(cleanAllCopilotHistory());
      dispatch(addCopilotHistory({ history: resp?.results }));
      if (resp?.next) {
        const limit = resp.next.match(/limit=(\d+)/i)[1];
        const offset = resp.next.match(/offset=(\d+)/i)[1];
        dispatch(setCopilotNextPage({ next_page: { limit, offset } }));
      }
      if (resp?.results) {
        const in_process = resp.results.find(item => (item?.remote_task_status === 'SCHEDULED' || item?.remote_task_status === 'IN_PROGRESS'));
        if (in_process) dispatch(setCopilotInProcess({ in_process: in_process?.id }));
      }
    }
  });
};

export const useCopilotHistoryNextPage = () => {
  const dispatch = useDispatch();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const next_page = useSelector(state => state?.copilot?.next_page) || null;
  return useMutation({
    mutationKey: 'copilotHistoryNextPage',
    mutationFn: () => (next_page ? requests.copilotHistoryNextPage({ portal_slug, params: next_page }) : undefined),
    onSuccess: (resp) => {
      if (resp) {
        dispatch(addCopilotHistory({ history: resp?.results }));
        if (resp?.next) {
          const limit = resp.next.match(/limit=(\d+)/i)[1];
          const offset = resp.next.match(/offset=(\d+)/i)[1];
          dispatch(setCopilotNextPage({ next_page: { limit, offset } }));
        } else {
          dispatch(setCopilotNextPage({ next_page: null }));
        }
      }
    }
  });
};

export const useCopilotAnswer = () => {
  const dispatch = useDispatch();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const chat_id = useSelector(state => state?.copilot?.in_process) || null;
  return useQuery({
    queryKey: 'copilotAnswer',
    refetchInterval: chat_id ? 5000 : false,
    refetchIntervalInBackground: true,
    enabled: COPILOT,
    queryFn: () => (chat_id ? requests.copilotDetail({ portal_slug, chat_id }) : undefined),
    onSuccess: (resp) => {
      if (chat_id) {
        const item = { ...resp };
        delete item?.messages;
        if (item?.remote_task_status === 'FINISHED') {
          dispatch(addCopilotHistory({ history: [item] }));
          dispatch(setCopilotInProcess({ in_process: null }));
          dispatch(setCopilotUnread({ unread: chat_id }));
        }
        if (item?.remote_task_status === 'FAILED') {
          dispatch(addCopilotHistory({ history: [item] }));
          dispatch(setCopilotInProcess({ in_process: null }));
          dispatch(setCopilotUnread({ unread: chat_id }));
        }
      }
    }
  });
};

export const useCopilotUpdate = () => {
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  return useMutation({
    mutationKey: 'copilotCreate',
    mutationFn: ({ chat_id, liked_messages_ids, disliked_messages_ids }) => requests.copilotUpdate({ portal_slug, chat_id, body: { liked_messages_ids, disliked_messages_ids } }),
    onError: () => {
      openNotification({ message: 'Error', isError: true });
    }
  });
};

export const useCopilotDelete = () => {
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const modalInfo = useSelector(state => state?.modals?.modalInfo);
  const dispatch = useDispatch();
  return useMutation({
    mutationKey: 'copilotCreate',
    mutationFn: chat_id => requests.copilotDelete({ portal_slug, chat_id }),
    onSuccess: () => {
      const chatID = modalInfo?.chatID;
      const openModalName = modalInfo?.openModalName;
      if (chatID) dispatch(deleteCopilotHistory({ chat_id: chatID }));
      if (openModalName) dispatch(openModal({ action: openModalName }));
    },
    onError: () => {
      openNotification({ message: 'Error', isError: true });
    }
  });
};
