import React from 'react';
import { array, shape, oneOfType, bool, string } from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import { ErrorBoundarySentry, Animated, Loader, IconSVG } from 'Components';
import moment from 'moment';
import { roundFnString } from 'utils/common';
import colors from 'styles/_colors.scss';
import classes from './BarChart.module.scss';

const toolTipElement = ({ data, id }) => (
  <div className={classes.tooltipWrap}>
    <IconSVG type={id} colored />
    <span>{data?.date}</span>
  </div>
);
const colorsCustom = {
  tv: colors.pink800,
  radio: colors.purpleA300,
  press: colors.yellow400,
  internet: colors.blue350,
  socialnetwork: colors.green350,
  forum: colors.font300,
  review: colors.orange500,
  default: colors.blue350
};
const BarChart = ({ data = [], period = '', isLoading = false }) => (
  <Animated visible={!!data?.length} duration={300} className={classes.barChart}>
    <ErrorBoundarySentry>
      <Loader size={45} fetching={isLoading} color={colors.font35}>
        {!!data?.length && (
          <ResponsiveBar
            data={data}
            keys={[
              'internet',
              'press',
              'radio',
              'socialnetwork',
              'tv',
              'forum',
              'review'
            ]}
            indexBy="date"
            enableLabel={false}
            enableGridY={false}
            enableGridX={false}
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: period === 'day' ? 'linear' : 'symlog' }}
            indexScale={{ type: 'band', round: true }}
            colors={({ id }) => colorsCustom[id] || colorsCustom.default}
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: colors.green500,
                size: 4,
                padding: 1,
                stagger: true
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: colors.yellow500,
                rotation: -45,
                lineWidth: 6,
                spacing: 10
              }
            ]}
            borderColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.6
                ]
              ]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              format(value) { return moment(value).format(period === 'day' ? 'DD.MM h:mm' : 'DD.MM'); },
              tickValues: 6,
              tickSize: 5,
              tickPadding: 10,
              tickRotation: 15
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 10,
              tickRotation: 0,
              tickValues: 6,
              format(value) { return roundFnString(+value); }
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.6
                ]
              ]
            }}
            tooltip={tooltip => toolTipElement(tooltip)}
                      // legends={[
            //   {
            //     dataFrom: 'keys',
            //     anchor: 'bottom-right',
            //     direction: 'column',
            //     justify: false,
            //     translateX: 120,
            //     translateY: 0,
            //     itemsSpacing: 2,
            //     itemWidth: 100,
            //     itemHeight: 20,
            //     itemDirection: 'left-to-right',
            //     itemOpacity: 0.85,
            //     symbolSize: 20,
            //     effects: [
            //       {
            //         on: 'hover',
            //         style: {
            //           itemOpacity: 1
            //         }
            //       }
            //     ]
            //   }
            // ]}
            role="application"
            // barAriaLabel={e => `${e.id} - ${e.formattedValue}`}
          />
        )}
      </Loader>
    </ErrorBoundarySentry>
  </Animated>

);

BarChart.propTypes = { data: oneOfType([array, shape({})]), period: string, isLoading: bool };

export default BarChart;
