import { useSelector } from 'react-redux';
import { Bolt } from 'icons';
import { Button } from 'Components';
import t from 'utils/translateFunc';
import colors from 'styles/_colors.scss';
import classes from './AIUsing.module.scss';

function AIUsing() {
  const limits = useSelector(state => state?.copilot?.limits);
  const { language } = useSelector(state => state?.userInfo);

  function usagePercent() {
    const usage = limits?.usage || 0;
    const limit = limits?.limit || 0;
    return Math.ceil((usage / limit));
  }

  return (
    <main>
      <div className={classes.part}>
        <div className={classes.usageCard}>
          <div className={classes.usageText}>
            <Bolt color={colors.purple350} />
            {t('COPILOT', 'AI_USING', language)}
          </div>
          <div className={classes.usageScale}>
            <div className={classes.scalePointer} style={{ width: usagePercent() }} />
          </div>
          <div className={classes.usageLimits}>
            <div>
              {limits?.usage || 0}
              &nbsp;
              {t('COPILOT', 'AI_USING_AVAILABLE', language)}
            </div>
            <div>
              {t('COPILOT', 'AI_USING_OUT_OF', language)}
              &nbsp;
              {limits?.limit || 0}
              &nbsp;
              {t('COPILOT', 'AI_USING_REQUESTS', language)}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.part}>
        <div className={classes.limitsUp}>
          <div className={classes.limitsImg} />
          <div className={classes.titleText}>
            {t('COPILOT', 'AI_USING_UP_TITLE', language)}
          </div>
          <div className={classes.mainText}>
            {t('COPILOT', 'AI_USING_UP_TEXT', language)}
          </div>
          <Button textButton={t('COPILOT', 'AI_USING_UP_BTN', language)} green32 />
        </div>
      </div>
    </main>
  );
}

export default AIUsing;
