import History from 'Containers/Mentions/Copilot/History';
import Messages from 'Containers/Mentions/Copilot/Messages';
import AIUsing from 'Containers/Mentions/Copilot/AIUsing';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { func } from 'prop-types';
import { More, Vector, AddCircle, History as HistoryIcon, Copy, DeleteType2, SmallScreen, Bolt, Info as InfoIcon } from 'icons';
import useClickOutside from 'hooks/clickOutside';
import { openNotification } from 'Components';
import { openModal, setCopilotUnread } from 'storage/actions';
import t from 'utils/translateFunc';
import colors from 'styles/_colors.scss';
import classes from './BodyCopilotFullScreen.module.scss';

function BodyCopilotFullScreen({ onClose = () => null }) {
  const [questionText, setQuestionText] = useState('');
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [isShowHistory, setIsShowHistory] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [menuMode, setMenuMode] = useState('messages');
  const history = useSelector(state => state?.copilot?.history);
  const unread = useSelector(state => state?.copilot?.unread);
  const modalInfo = useSelector(state => state?.modals?.modalInfo);
  const { language } = useSelector(state => state?.userInfo);
  const dispatch = useDispatch();
  const menuBtnRef = useRef(null);
  useClickOutside(menuBtnRef, () => (showMenu ? setShowMenu(false) : null));

  useEffect(() => {
    // Updating parent element style
    const current_element = document.getElementById('CopilotModal');
    const buttons = current_element.parentElement.parentElement.getElementsByTagName('button');
    if (buttons.length) buttons[0].remove();
    const modal_element = current_element.parentElement.parentElement;
    modal_element.style.borderRadius = '12px';
  }, []);

  useEffect(() => {
    if (activeQuestion?.id === unread) dispatch(setCopilotUnread({ unread: null }));
  }, [activeQuestion]);

  useEffect(() => {
    if (modalInfo) {
      const chat_id = modalInfo?.chat_id || null;
      const question = modalInfo?.question || '';
      if (chat_id) selectQuestion(chat_id);
      if (question) setQuestionText(question);
      return;
    }
    if (!activeQuestion && history?.length && unread) {
      selectQuestion(unread);
    }
  }, []);

  function selectMode(mode) {
    setMenuMode(mode);
    setShowMenu(false);
  }

  function newChat() {
    setActiveQuestion(null);
    selectMode('messages');
  }

  function selectQuestion(question_id) {
    const question = history.find(q => q?.id === question_id);
    setActiveQuestion(question);
  }

  function deleteChat() {
    dispatch(openModal({ action: 'copilotDelete', chatID: activeQuestion?.id, openModalName: 'copilotFullScreen' }));
  }

  async function onClickCopy() {
    const copy_text = activeQuestion?.answer || '';
    await navigator.clipboard.writeText(copy_text);
    openNotification({ message: 'Copied' });
  }

  return (
    <div id="CopilotModal" className={classes.fullScreen}>
      <header className={classes.header}>
        <div className={classes.headerItem}>
          {menuMode === 'messages' && <div className={classes.headerIconRainbow}><Vector /></div>}
          {menuMode === 'using' && <div className={classes.headerIconPurple}><Bolt color="white" /></div>}
          {menuMode === 'info' && <div className={classes.headerIconPurple}><span>i</span></div>}
          <div className={classes.headerText}>
            <div className={classes.upperText}>Q Insight</div>
            <div className={classes.bottomText}>by ChatGPT</div>
          </div>
        </div>
        <div className={classes.actions}>
          <div className={classes.button} onClick={newChat}>
            <AddCircle color={colors.font70} />
          </div>
          {menuMode === 'messages' && (
            <div className={classes.button} onClick={() => setIsShowHistory(!isShowHistory)}>
              <HistoryIcon color={colors.font70} />
            </div>
          )}
          {(activeQuestion?.id && (activeQuestion?.remote_task_status === 'FAILED' || activeQuestion?.remote_task_status === 'FINISHED')) && (
            <>
              <div className={classes.button} onClick={onClickCopy}>
                <Copy color={colors.font70} />
              </div>
              <div className={classes.button} onClick={deleteChat}>
                <DeleteType2 color={colors.font70} />
              </div>
            </>
          )}
          <div className={classes.button} onClick={onClose}>
            <SmallScreen color={colors.font70} />
          </div>
          <div>
            <div className={classes.button} onClick={() => setShowMenu(!showMenu)}>
              <More color={colors.font70} />
            </div>
            <ul className={classes.dropdownMenu} style={{ visibility: showMenu ? 'visible' : 'hidden' }} ref={menuBtnRef}>
              <li>
                <div className={classes.dropdownItem} onClick={() => selectMode('using')}>
                  <Bolt color={colors.font70} />
                  {t('COPILOT', 'CHAT_USING', language)}
                </div>
              </li>
              <li>
                <div className={classes.dropdownItem} onClick={() => selectMode('info')}>
                  <InfoIcon color={colors.font70} />
                  {t('COPILOT', 'CHAT_INFO', language)}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <div className={classes.mainPart}>
        {(isShowHistory && menuMode === 'messages') && (
          <div className={classes.historyPart}>
            <History chats_list={history} select_chat={selectQuestion} />
          </div>
        )}
        <div className={classes.chatPart}>
          {menuMode === 'messages' && <Messages chat={activeQuestion} set_chat={setActiveQuestion} questionText={questionText} setQuestionText={setQuestionText} />}
          {menuMode === 'using' && <AIUsing />}
          {menuMode === 'info' && <main><p>Info</p></main>}
        </div>
      </div>
    </div>
  );
}

BodyCopilotFullScreen.propTypes = {
  onClose: func
};

export default BodyCopilotFullScreen;
