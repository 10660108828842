import { useState } from 'react';
import cn from 'classnames';
import { bool, string, func } from 'prop-types';
import { getIconName, MEDIA_TYPES, SOCIAL_NETWORKS } from 'data/sourceConfig';
import colors from 'styles/_colors.scss';
import classes from './IconSVG.module.scss';

export function IconSVG({ type = '', src = '', iconWrapClass = '', iconClass = '', colored = false, handleClick = () => null }) {
  const [isBroken, setIsBroken] = useState(false);
  const isSocial = !!type && !!SOCIAL_NETWORKS.includes(type);
  const isMedia = !!type && !!MEDIA_TYPES.includes(type);

  const filledColors = {
    internet: colors.blueA325,
    press: colors.yellow400,
    forum: colors.font300,
    agency: colors.green475,
    review: colors.orange500,
    radio: colors.purpleA300,
    tv: colors.pink800,
    total: colors.totalColor,
    default: colors.green350m
  };

  function checkGroup() {
    if (isSocial) {
      return 'socialnetwork';
    }
    if (isMedia) {
      return 'media_type';
    }
    if (type === 'country') {
      return 'country';
    }
    return 'default';
  }

  const typeGroup = type && checkGroup();

  const folders = {
    media_type: '/assets/svg/media_type',
    socialnetwork: '/assets/svg/socialnetwork',
    country: '/assets/svg/country',
    default: '/assets/svg'
  };
  const iconName = type || 'default';
  const path = folders[typeGroup];
  const localPath = `${path}/${getIconName(iconName)}.svg`;
  const outPath = !!src && (isBroken ? localPath : src);

  return (
    <div
      className={cn(
        classes.iconWrap,
        isMedia && classes.iconWrap_media,
        isMedia && colored && classes.iconWrap_white,
        iconWrapClass
      )}
      style={{ ...(isMedia && colored ? { backgroundColor: (filledColors[type] || filledColors.default) } : {}) }}
      onClick={handleClick}
    >
      <img
        src={outPath || localPath}
        onError={() => { setIsBroken(true); }}
        alt="icon"
        className={cn(iconClass)}
      />
    </div>
  );
}

IconSVG.propTypes = {
  type: string,
  src: string,
  iconWrapClass: string,
  iconClass: string,
  colored: bool,
  handleClick: func
};
